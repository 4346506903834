const TeamSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("teams");
    },
  };

  return svc;
};

export default TeamSvc;
