const CompanyPartnerSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("company_partners");
    },

    one(id) {
      return Restangular.one("company_partners", id);
    },
    rang(cp) {
      return (cp = Restangular.restangularizeElement(null, cp, "company_partners"));
    },
  };

  return svc;
};
export default CompanyPartnerSvc;
