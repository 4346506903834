// import urls from "../utils/urls";
import urls from "~angular/src/rj_shared/utils/urls"

const appInfo = function ($browser, $window) {
  "ngInject"

  const data: any = {
    app_url_root: urls.host(),
    protocol: $window.location.protocol,
    init: function (app_config) {
      data.api_version = app_config.api_version
      data.api_url = "/api/v1"
      data.client_url = data.protocol + "//" + data.app_url_root
      data.app_name = app_config.name
      data.base_href = $browser.baseHref()
      data.partner_client_url = data.protocol + "//" + process.env.DEFAULT_CLIENT_SUBDOMAIN + urls.cookieDomainUrl()
      data.portal_server_url = data.protocol + "//" + data.app_url_root
      data.protocol = $window.location.protocol
    },
  }

  return data
}

export default appInfo
