import { ListEditor } from "./listEditor"

const listEditor = function () {
  "ngInject"
  return {
    restrict: "E",
    require: "",
    scope: {
      isDisabled: "=",
      arrayModel: "=",
      arrayModelAddItem: "&",
      pathToTextProperty: "<", // optional, if we know the path in advance
      uuid: "<", // optional, we can pass a uuid
      beforeDelete: "&",
      externalSelectedLines: "=",
      builderHelper: "=",
    },
    templateUrl: "rj_shared/listEditorWrapper.html",
    controller: [
      "$scope",
      "$timeout",
      "$rootScope",
      function ($scope, $timeout, $rootScope) {
        "ngInject"

        if (!Array.isArray($scope.arrayModel)) {
          throw new Error('Must pass an array to "array-model".')
        }

        // This builds the text content in the text editor from the array.
        // 1. pass in an array
        // 2. pass in the key containing the text content
        // 3. these two functions will map all of the text content in the keys to a single string

        // initialize the key property safely in case array has a length of 0

        // data that shows up in the html
        const vm = {
          uuid: $scope.uuid || guid(),
          arrayModel: $scope.arrayModel, // note: this must never be re-assigned. it is a reference to the object that is passed in.
        }

        $scope.vm = vm

        function onActiveLinesChange(activeLines: any, uuid: string) {
          $scope.$emit("active-lines", activeLines, uuid)
          refreshDOMFunc()
        }

        function refreshDOMFunc() {
          $timeout(() => {
            $scope.$apply()
          })
        }

        $timeout(() => {
          const textarea: any = document.getElementById(vm.uuid)
          const listEditor = new ListEditor({
            isDisabled: $scope.isDisabled,
            arrayModel: $scope.arrayModel,
            arrayModelAddItem: $scope.arrayModelAddItem,
            pathToTextProperty: $scope.pathToTextProperty,
            refreshDOMFunc,
            onActiveLinesChange,
            textarea,
            uuid: vm.uuid,
            beforeDelete: $scope.beforeDelete,
            builderHelper: $scope.builderHelper,
          })
          $scope.$watch(
            "arrayModel",
            (newArr, oldArr) => {
              listEditor.arrayChanged(newArr, oldArr)
            },
            true,
          )
          $scope.$watch("pathToTextProperty", newPath => {
            listEditor.textPropertyChanged(newPath)
          })
          $rootScope.$on("localeChangeEvent", (locale: any) => {
            listEditor.textPropertyChanged($scope.pathToTextProperty)
          })
        }, 0)
      },
    ],
  }
}

export default listEditor
