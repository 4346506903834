const ProjectSvc = function (Restangular) {
  "ngInject"

  //Restangular.extendCollection "samples", (list) ->
  //  list
  Restangular.extendModel("projects", function (obj) {
    if (obj && typeof obj === "object") {
      obj.restore = () => obj.customPUT({ archived_at: null })
    }
    return obj
  })

  const svc = {
    all() {
      return Restangular.all("projects")
    },
    all_paged(params) {
      if (params == null) {
        params = {}
      }
      return Restangular.all("projects").customGET("paged", params)
    },
    one(id) {
      return Restangular.one("projects", id)
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {}
      }
      const new_attrs = { name: "" }
      const project = jQuery.extend(true, new_attrs, attrs)
      return Restangular.restangularizeElement(null, project, "projects")
    },
  }
  return svc
}

export default ProjectSvc
