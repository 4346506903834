miniSidebar = (
) ->
  "ngInject"

  return {
    restrict: 'E',
    replace: true,
    controller: 'miniSideCtrl',
    templateUrl: "ui/nav/mini_side_bar.html"
  }

export default miniSidebar

