import * as _ from "lodash"

const ParticipantGroupSvc = function (Restangular, $stateParams, currentUser) {
  "ngInject"

  Restangular.extendModel("participant_groups", function (pg) {
    if (pg && typeof pg === "object") {
      try {
        let props
        pg.anyErrors = function (props) {
          if (!pg.errors) {
            return false
          }
        }
        if (!$.isArray(props)) {
          props = [props]
        }
        _.any(pg.errors, (msg, p) => _.any(props, prop => prop === p))
      } catch (e) {}
    }
    return pg
  })

  const svc = {
    all() {
      return Restangular.all("participant_groups")
    },
    one(id) {
      return Restangular.one("participant_groups", id)
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {}
      }
      const new_attrs = { padding_ratio: currentUser.padding_ratio }

      const me = jQuery.extend(true, new_attrs, attrs)
      return Restangular.restangularizeElement(null, me, "participant_groups")
    },
  }

  return svc
}

export default ParticipantGroupSvc
