const AnalysisSampleSvc = function (Restangular, $stateParams) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("analysis_samples");
    },
  };

  return svc;
};

export default AnalysisSampleSvc;
