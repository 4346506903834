rjLoader = (
) ->
  "ngInject"

  restrict: "AE"
  replace: true
  template: "<div class=\"loading-container\">" + "<ul class=\"bokeh\">" + "<li></li>" + "<li></li>" + "<li></li>" + "<li></li>" + "</ul>" + "</div>"
  link: ($scope, element, attrs) ->

export default rjLoader

