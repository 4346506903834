const EventHostingRequestSvc = function (Restangular) {
  "ngInject"

  Restangular.extendModel("event_hosting_requests", function (obj) {
    if (obj && typeof obj === "object") {
      if (obj.start_date) {
        obj.start_date_obj = Date.parse(obj.start_date)
      }
      if (obj.end_date) {
        obj.end_date_obj = Date.parse(obj.end_date)
      }
    }
    return obj
  })

  const svc = {
    all() {
      return Restangular.all("event_hosting_requests")
    },
    one(id) {
      return Restangular.one("event_hosting_requests", id)
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {}
      }
      const new_attrs = {
        preapproved: false,
      }
      const model = jQuery.extend(true, new_attrs, attrs)
      return Restangular.restangularizeElement(null, model, "event_hosting_requests")
    },
  }
  return svc
}

export default EventHostingRequestSvc
