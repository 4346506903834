import * as _ from "lodash"

const PanelSvc = function (Restangular) {
  "ngInject"

  Restangular.extendModel("panels", function (panel) {
    if (panel && typeof panel === "object") {
      if (panel.panel_subjects) {
        Restangular.restangularizeCollection(null, panel.panel_subjects, "panel_subjects")
        _.map(panel.panel_subjects, ps => (ps.fromServer = true))
      }
    }
    return panel
  })

  const svc = {
    all() {
      return Restangular.all("panels")
    },
    one(id) {
      return Restangular.one("panels", id)
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {}
      }
      const new_attrs = {
        name: "",
        subjects: [],
      }
      const obj = jQuery.extend(true, new_attrs, attrs)
      const rest_obj = Restangular.restangularizeElement(null, obj, "panels", {})
      rest_obj.fromServer = false
      return rest_obj
    },
  }

  return svc
}

export default PanelSvc
