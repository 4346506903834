preferredDateTime = (
) ->
  "ngInject"

  (value, campaign = null) ->
    return unless moment(value).isValid()

    # let campaign override company settings if they exist
    if campaign && campaign.date_format
      if campaign.date_format == 'dd/mm/yy'
        date_format = "DD MMMM YYYY"
      else
        date_format = "MMMM DD YYYY"
      if campaign.time_format && campaign.time_format == '24hour'
        time_format = " HH:mm"
      else
        time_format = " hh:mm A"
    else if current_user.date_format
      if current_user.date_format == 'dd/mm/yy'
        date_format = "DD MMMM YYYY"
      else
        date_format = "MMMM DD YYYY"
      if current_user.time_format && current_user.time_format == '24hour'
        time_format = " HH:mm"
      else
        time_format = " hh:mm A"
    else
      date_format = "DD MMMM YYYY"
      time_format = " hh:mm A"

    format = date_format + time_format
    return moment(value).format(format)
export default preferredDateTime

