const ModalitySvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("modalities");
    },
    one(id) {
      return Restangular.one("modalities", id);
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {
        sensory_test_id: null,
        code: null,
        name: null,
      };
      const modality = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, modality, "modalities", null);
    },
  };
  return svc;
};

export default ModalitySvc;
