import * as angular from "angular"

propsFilter = (
) ->
  "ngInject"

  GetValue = (item, propertyName) ->
    firstDot = propertyName.indexOf(".")
    if firstDot isnt -1
      prop = propertyName.substr(0, firstDot)
      remainder = propertyName.substr(firstDot + 1)
      GetValue item[prop], remainder
    else
      item[propertyName]
  (items, props) ->
    out = []
    if angular.isArray(items)
      items.forEach (item) ->
        itemMatches = false
        keys = Object.keys(props)
        i = 0

        while i < keys.length
          prop = keys[i]
          text = props[prop].toLowerCase()
          value = GetValue(item, prop)
          if value and value.toString().toLowerCase().indexOf(text) isnt -1
            itemMatches = true
            break
          i++
        out.push item  if itemMatches
        return

    else

      # Let the output be the input untouched
      out = items
    out

export default propsFilter

