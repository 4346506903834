import * as _ from "lodash";
import * as angular from "angular";
import cookies from "../utils/cookies";

const currentUser = function (Restangular, $q) {
  "ngInject";

  const base = {
    authenticate() {
      //this is here because surveys is still doing a half of pages are auth'd thing
      const deferred = $q.defer();
      Restangular.one("users", "me")
        .get(null, { Authorization: `Bearer ${cookies.getCookie("auth")}` })
        .then(
          result => {
            _.extend(this, result);
            return deferred.resolve(this);
          },
          error => deferred.reject(error),
        );
      return deferred.promise;
    },

    can(permission) {
      return _.includes(this.clearance_levels, permission);
    },

    can_access(domain) {
      const app = _.find(this.accessible_applications, { domain });
      if (app) {
        return true;
      } else {
        return false;
      }
    },
  };

  // @ts-ignore
  angular.extend(base, window.current_user);
  return base;
};

export default currentUser;
