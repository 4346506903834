const QuestionSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("questions");
    },
    one(id) {
      return Restangular.one("questions", id);
    },
    validate_profiles(id, ack = null) {
      return Restangular.one("questions", id).one("validate_profiles").customGET("", { ack });
    },
  };

  return svc;
};

export default QuestionSvc;
