preferredDate = (
) ->
  "ngInject"

  (value, campaign = null) ->
    return unless moment(value).isValid()

    # let campaign override company settings if they exist
    if campaign && campaign.date_format
      if campaign.date_format == 'dd/mm/yy'
        date_format = "DD MMMM YYYY"
      else
        date_format = "MMMM DD YYYY"
    else if current_user.date_format
      if current_user.date_format == 'dd/mm/yy'
        date_format = "DD MMMM YYYY"
      else
        date_format = "MMMM DD YYYY"
    else
      date_format = "DD MMMM YYYY"

    return moment(value).format(date_format)
export default preferredDate

