globalAlerts = (
) ->
  "ngInject"

  return {
    restrict: 'E',
    replace: true,
    controller: 'globalAlertsCtrl',
  }

export default globalAlerts

