const OauthApplicationSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all(company_id) {
      return Restangular.all(`oauth/applications/company_applications/${company_id}`);
    },
    one(id) {
      return Restangular.one("oauth/applications", id);
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = { name: "" };
      const obj = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, obj, "oauth/applications");
    },
  };
  return svc;
};

export default OauthApplicationSvc;
