const QuestionTemplateFolderSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("question_template_folders");
    },
    one(id) {
      return Restangular.one("question_template_folders", id);
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {
        title: "",
        user_id: null,
        company_id: null,
      };

      const folder = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, folder, "question_template_folders");
    },
  };

  return svc;
};

export default QuestionTemplateFolderSvc;
