
import * as angular from "angular"

require("./globals.css")

utils = angular.module('redjade.ui.utils', [])
  .controller('attachmentsModalCtrl', require("./attachments.controller").default)
  .controller('dialogCtrl', require("./dialog.controller").default)
  .controller('emailComposerCtrl', require("./email_composer_modal.controller").default)
  .controller('tagModalCtrl', require("./tag_modal.controller").default)
  .run(($templateCache) ->
    "ngInject"
    $templateCache.put('rj_shared/attachments_modal.html', require("./attachments_modal.html"))
    $templateCache.put('rj_shared/dialog.html', require("./dialog.html"))
    $templateCache.put('rj_shared/email_composer_modal.html', require("./email_composer_modal.html"))
    $templateCache.put('rj_shared/tag_modal.html', require("./tag_modal.html"))
  )
  .name

export default utils
