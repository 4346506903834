import cookies from "../utils/cookies";

const importHelper = function () {
  "ngInject";

  const securityOptions = () => ({
    xhrFields: { withCredentials: true },
    headers: {
      "X-CSRF-Token": $("meta[name=csrf-token]").attr("content"),
      Authorization: "Bearer " + cookies.getCookie("auth"),
    },
  });

  return {
    securityOptions,
  };
};

export default importHelper;
