const QuestionnaireSvc = function (Restangular) {
  "ngInject"

  Restangular.extendModel("questionnaires", function (questionnaire) {
    if (questionnaire && typeof questionnaire === "object") {
      questionnaire.recruitParticipantCount = function () {
        if (!questionnaire.needed_participant_count || !questionnaire.padding_ratio) {
          return 0
        }
        return questionnaire.needed_participant_count * questionnaire.padding_ratio
      }
    }
    return questionnaire
  })

  const svc = {
    all() {
      return Restangular.all("questionnaires")
    },
    one(id) {
      return Restangular.one("questionnaires", id)
    },
    lookup() {
      return Restangular.all("questionnaires/lookup")
    },
    search(term) {
      return Restangular.allUrl("questionnaires/search?term=" + term)
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {}
      }
      const new_attrs = {}
      const model = jQuery.extend(true, new_attrs, attrs)
      return Restangular.restangularizeElement(null, model, "questionnaires")
    },
  }

  return svc
}

export default QuestionnaireSvc
