const FieldSvc = function (Restangular, $stateParams) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("fields");
    },
    one(id) {
      return Restangular.one("fields", id);
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {
        company_id: null,
        name: null,
        field_type: null,
      };
      const data = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, data, "fields", false);
    },
  };

  return svc;
};

export default FieldSvc;
