const ParticipantSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("participants");
    },
    export_participant_samples_report(params) {
      if (params == null) {
        params = {};
      }
      return Restangular.all("participants").customPOST(params, "export_participant_samples_report");
    },
    one(id) {
      return Restangular.one("participants", id);
    },
    skip(id) {
      return Restangular.one(`participants/${id}/skip`);
    },
    abandon(id) {
      return Restangular.one(`participants/${id}/abandon`);
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {
        name: "",
        subject_id: "",
        event_id: "",
        email: "",
        sensory_test_id: "",
        demographic_ids: [],
      };
      const obj = jQuery.extend(true, new_attrs, attrs);
      const rest_obj = Restangular.restangularizeElement(null, obj, "participants", {});
      rest_obj.fromServer = false;
      return rest_obj;
    },
  };

  return svc;
};

export default ParticipantSvc;
