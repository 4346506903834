#to disable the way that scrolling changes the data for a <input type="number" /> you need to blur on mousewheel
scrollBlur = (
) ->
  "ngInject"

  return {
    link: ($scope, element, attributes) ->
      element.on('mousewheel', (e) ->
        $(this).blur()
        return
      )
  }


export default scrollBlur

