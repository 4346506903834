import * as _ from "lodash";

const participantSearchSvc = function ($rootScope) {
  "ngInject";

  const checkbox_hack = false;
  const table = null;

  const data: any = {
    total: null,
    filter_info: {
      search_term: null,
    },
    selected_participants: [],
  };

  const getSearchData = params => ({
    search_term: data.filter_info.search_term,
    selected_subjects: data.selected_subjects,
  });

  const initializeFilterInfo = function (params) {
    data.set_filter_sidebar = false;
    data.total = null;
    const search_params = params;
    data.selected_participants = [];
    this.checkbox_hack = false;
    data.filter_info = {
      order_by: search_params.order_by || "code",
      order_dir: search_params.order_dir || "asc",
      page: search_params.page || 1,
      per: search_params.per || 10,
      search_term: search_params.search_term || "",
    };
  };

  const toggleInSelectedList = function (participant_id) {
    if (_.includes(data.selected_participants, participant_id)) {
      data.selected_participants = _.without(data.selected_participants, participant_id);
      return this.areAllSelected();
    } else {
      data.selected_participants.push(participant_id);
      return this.areAllSelected();
    }
  };

  const areAllSelected = function () {
    if (this.table) {
      const diff = _.difference(_.map(this.table.data, "id"), data.selected_participants);
      if (diff.length === 0 && data.selected_participants.length > 0) {
        return (this.checkbox_hack = true);
      } else {
        return (this.checkbox_hack = false);
      }
    } else {
      return (this.checkbox_hack = false);
    }
  };

  const areSomeSelected = function () {
    const inter = _.intersection(_.map(this.table.data, "id"), data.selected_participants);
    return inter.length > 0 && inter.length !== this.table.data.length;
  };

  const toggleSelectAll = function () {
    const inter = _.intersection(_.map(this.table.data, "id"), data.selected_participants);
    if (inter.length === 0) {
      return _.each(this.table.data, participant => data.selected_participants.push(participant.id));
    } else {
      const ids_to_remove = _.map(this.table.data, "id");
      data.selected_participants = _.difference(data.selected_participants, ids_to_remove);
      return (this.checkbox_hack = false);
    }
  };

  const isParticipantSelected = participant_id => _.includes(data.selected_participants, participant_id);

  const changed = (options = null) => $rootScope.$broadcast("LIST_SEARCH_CHANGED", options);

  return {
    data,
    table,
    checkbox_hack,
    pluralize: {
      one: "1 participant",
      other: "{} participants",
    },

    getSearchData,
    initializeFilterInfo,
    toggleInSelectedList,
    toggleSelectAll,
    areAllSelected,
    areSomeSelected,
    isParticipantSelected,
    changed,
  };
};

export default participantSearchSvc;
