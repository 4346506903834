import { BuilderHelperClass } from "../../survey_builder/services/builderHelper.factory"
import { QuestionBuilder, AnswersEntity } from "../../apps/analyses/ui/analysis_topline/question-builder"

const listEditorBuilderWrapper = function () {
  return {
    restrict: "E",
    scope: {
      useImages: "<",
      useValues: "<",
      useVerticalTables: "<",
      useSettings: "<",
      useDelete: "<",
      isGroup: "<",
      isMaxDiff: "<",
      question: "=",
    },

    controller: [
      "$scope",
      "builderHelper",
      "$rootScope",
      function ($scope, builderHelper: BuilderHelperClass, $rootScope) {
        "ngInject"
        const activeLines: { [key: number]: boolean } = {}

        const verticalTable = (question: QuestionBuilder) => {
          return question.config.orientation == "vertical_table"
        }
        const includeHeader = (question: QuestionBuilder) => {
          return question.config.include_header
        }

        const setActiveAnswer = (answer: AnswersEntity) => (builderHelper.active_answer = answer)

        $scope.$on("active-lines", (event: any, data: any, uuid: any) => {
          $rootScope.activeLines = {
            [vm.uuid]: data,
          }
        })

        $scope.beforeDelete = async function () {
          return builderHelper.confirmDataDelete($scope.question, "answers")
        }

        const vm = {
          uuid: guid(),
          activeLines,
          question: builderHelper.active_question,
          verticalTable,
          includeHeader,
          setActiveAnswer,
          builderHelper,
        }

        $scope.switchHack = function (groupValue, maxDiffValue, defaultValue) {
          if ($scope.isGroup) {
            return groupValue
          } else if ($scope.isMaxDiff) {
            return maxDiffValue
          } else {
            return defaultValue
          }
        }

        $scope.previewText = function (text, builderHelper) {
          var locales = builderHelper.locales
          var currentLocale = builderHelper.locale
          var defaultLocale = locales[0]
          var placeholder = ""

          if (currentLocale != defaultLocale && (!text[currentLocale] || text[currentLocale].trim() == ""))
            placeholder = text[defaultLocale]

          if (!placeholder || placeholder.trim() == "") return placeholder

          return `<< ${builderHelper.languageHelper.languages[defaultLocale]} >> ${placeholder}`
        }

        $scope.builderHelper = builderHelper
        $scope.question = $scope.question || builderHelper.active_question
        $scope.vm = vm
      },
    ],
    templateUrl: "rj_shared/listEditorBuilderWrapper.html",
  }
}

export default listEditorBuilderWrapper
