const stateSvc = function ($state, $location) {
  "ngInject";

  const svc: any = {};

  svc.states = {};

  svc.getState = name => {
    return svc.states[name] || {};
  };

  svc.setState = (name, obj) => {
    return (svc.states[name] = obj);
  };

  svc.getCurrentState = () => {
    return svc.states[$state.current.name] || {};
  };

  svc.setCurrentState = obj => {
    svc.states[$state.current.name] = obj;
    return svc.updateQueryParams();
  };

  svc.updateQueryParams = () => {
    return $location.search(svc.states[$state.current.name]);
  };

  return svc;
};

export default stateSvc;
