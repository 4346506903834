import * as _ from "lodash"
import * as angular from "angular"

const FileAttachmentSvc = function (Restangular, $window, appInfo) {
  "ngInject"

  Restangular.extendModel("file_attachments", obj => {
    if (obj && typeof obj === "object") {
      angular.extend(obj, {
        isImage() {
          return _.includes(["image/jpeg", "image/jpg", "image/png"], obj.source_content_type)
        },
      })
    }
    return obj
  })

  const svc = {
    all(owner_type, owner_id) {
      return Restangular.all(`${owner_type}/${owner_id}/file_attachments`)
    },
    one(id) {
      return Restangular.one("file_attachments", id)
    },
    convert(owner_type, owner_id, attrs) {
      if (attrs == null) {
        attrs = {}
      }
      const obj = jQuery.extend(true, {}, attrs)
      const rest_obj = Restangular.restangularizeElement(null, obj, `${owner_type}/${owner_id}/file_attachments`, {})
      rest_obj.fromServer = true
      return rest_obj
    },
    ical(params, action) {
      return Restangular.oneUrl("ical", `${appInfo.api_url}/calendar_attachments/${action}`)
        .get(params, { responseType: "arraybuffer" })
        .then(function (response) {
          // Create a Blob from the response data
          const blob = new Blob([response], { type: "text/calendar" })

          // Create a URL for the Blob
          const url = $window.URL || $window.webkitURL
          const fileUrl = url.createObjectURL(blob)

          // Create a temporary anchor element
          const anchor = angular.element("<a>")
          anchor.attr({
            href: fileUrl,
            download: "calendar.ics",
          })

          // Append the anchor element to the document body
          angular.element(document.body).append(anchor)

          // Trigger a click event on the anchor element
          anchor[0].click()

          // Clean up the temporary anchor and URL object
          anchor.remove()
          return url.revokeObjectURL(fileUrl)
        })
        .catch(error => console.error("Error downloading iCal:", error))
    },
  }

  return svc
}

export default FileAttachmentSvc
