import * as angular from "angular";

const redjade_services = angular
  .module("redjade.services", [])
  .service("appInfo", require("./appInfo.service").default)
  .service("appStateSvc", require("./appState.service").default)
  .service("commonFunctions", require("./commonFunctions.service").default)
  .constant("COUNTRIES", require("./countries.constant").default)
  .service("currentUser", require("./currentUser.service").default)
  .factory("experienceHelper", require("./experienceHelper.factory").default)
  .factory("geocoder", require("./geocoder.factory").default)
  .factory("imageUrlHelper", require("./imageUrlHelper.factory").default)
  .factory("importHelper", require("./importHelper.factory").default)
  .factory("messageHelper", require("./messageHelper.factory").default)
  .factory("participantSearchSvc", require("./participantSearch.factory").default)
  .factory("participantStep", require("./participantStep.factory").default)
  .factory("pdfSvc", require("./pdf.service.ts").default)
  .factory("previousStateHelper", require("./previousStateHelper.factory").default)
  .factory("sensoryTestSearchSvc", require("./sensoryTestSearch.factory").default)
  .factory("stateHelper", require("./stateHelper.factory").default)
  .factory("stateSvc", require("./state.factory").default)
  .factory("translationLoader", require("./translationLoader.factory").default)
  .factory("validationHelper", require("./validatonHelper.factory").default)
  .run(function ($templateCache) {
    "ngInject";

    $templateCache.put(
      "uib/template/datepicker/day.html",
      '<table role="grid" aria-labelledby="{{::uniqueId}}-title" aria-activedescendant="{{activeDateId}}" class="dp-table dpt-day"><thead><tr class="tr-dpnav"><th><button type="button" class="pull-left btn-dp" ng-click="move(-1)" tabindex="-1"><i class="zmdi zmdi-long-arrow-left"></i></button></th><th colspan="{{::5 + showWeeks}}"><button id="{{::uniqueId}}-title" role="heading" aria-live="assertive" aria-atomic="true" type="button" ng-click="toggleMode()" ng-disabled="datepickerMode === maxMode" tabindex="-1" class="w-100 btn-dp"><div class="dp-title">{{title}}</div></button></th><th><button type="button" class="pull-right btn-dp" ng-click="move(1)" tabindex="-1"><i class="zmdi zmdi-long-arrow-right"></i></button></th></tr><tr class="tr-dpday"><th ng-if="showWeeks" class="text-center"></th><th ng-repeat="label in ::labels track by $index" class="text-center"><small aria-label="{{::label.full}}">{{::label.abbr}}</small></th></tr></thead><tbody><tr ng-repeat="row in rows track by $index"><td ng-if="showWeeks" class="text-center h6"><em>{{ weekNumbers[$index] }}</em></td><td ng-repeat="dt in row track by dt.date" class="text-center" role="gridcell" id="{{::dt.uid}}" ng-class="::dt.customClass"><button type="button" class="w-100 btn-dp btn-dpday btn-dpbody" ng-class="{\'dp-today\': dt.current, \'dp-selected\': dt.selected, \'dp-active\': isActive(dt)}" ng-click="select(dt.date)" ng-disabled="dt.disabled" tabindex="-1"><span ng-class="::{\'dp-day-muted\': dt.secondary, \'dp-day-today\': dt.current}">{{::dt.label}}</span></button></td></tr></tbody></table>',
    );
    $templateCache.put(
      "uib/template/datepicker/month.html",
      '<table role="grid" aria-labelledby="{{::uniqueId}}-title" aria-activedescendant="{{activeDateId}}" class="dp-table"><thead><tr class="tr-dpnav"><th><button type="button" class="pull-left btn-dp" ng-click="move(-1)" tabindex="-1"><i class="zmdi zmdi-long-arrow-left"></i></button></th><th><button id="{{::uniqueId}}-title" role="heading" aria-live="assertive" aria-atomic="true" type="button" ng-click="toggleMode()" ng-disabled="datepickerMode === maxMode" tabindex="-1" class="w-100 btn-dp"><div class="dp-title">{{title}}</div></button></th><th><button type="button" class="pull-right btn-dp" ng-click="move(1)" tabindex="-1"><i class="zmdi zmdi-long-arrow-right"></i></button></th></tr></thead><tbody><tr ng-repeat="row in rows track by $index"><td ng-repeat="dt in row track by dt.date" class="text-center" role="gridcell" id="{{::dt.uid}}" ng-class="::dt.customClass"><button type="button" class="w-100 btn-dp btn-dpbody" ng-class="{\'dp-selected\': dt.selected, \'dp-active\': isActive(dt)}" ng-click="select(dt.date)" ng-disabled="dt.disabled" tabindex="-1"><span ng-class="::{\'dp-day-today\': dt.current}">{{::dt.label}}</span></button></td></tr></tbody></table>',
    );
    $templateCache.put(
      "uib/template/datepicker/popup.html",
      '<ul class="dropdown-menu" ng-keydown="keydown($event)"><li ng-transclude></li><li ng-if="showButtonBar" class="dp-actions clearfix"><button type="button" class="btn btn-link" ng-click="select(\'today\', $event)">{{ getText(\'current\') }}</button>  <button type="button" class="btn btn-link" ng-click="select(null, $event)">{{ getText(\'clear\') }}</button>    <button type="button" class="btn btn-link" ng-click="close($event)">{{ getText(\'close\') }}</button></li></ul>',
    );
    return $templateCache.put(
      "uib/template/datepicker/year.html",
      '<table role="grid" aria-labelledby="{{::uniqueId}}-title" aria-activedescendant="{{activeDateId}}" class="dp-table"><thead><tr class="tr-dpnav"><th><button type="button" class="pull-left btn-dp" ng-click="move(-1)" tabindex="-1"><i class="zmdi zmdi-long-arrow-left"></i></button></th><th colspan="3"><button id="{{::uniqueId}}-title" role="heading" aria-live="assertive" aria-atomic="true" type="button" class="w-100 btn-dp" ng-click="toggleMode()" ng-disabled="datepickerMode === maxMode" tabindex="-1"><div class="dp-title">{{title}}</div></button></th><th><button type="button" class="pull-right btn-dp" ng-click="move(1)" tabindex="-1"><i class="zmdi zmdi-long-arrow-right"></i></button></th></tr></thead><tbody><tr ng-repeat="row in rows track by $index"><td ng-repeat="dt in row track by dt.date" class="text-center" role="gridcell" id="{{::dt.uid}}"><button type="button" class="w-100 btn-dp btn-dpbody" ng-class="{\'dp-selected\': dt.selected, \'dp-active\': isActive(dt)}" ng-click="select(dt.date)" ng-disabled="dt.disabled" tabindex="-1"><span ng-class="::{\'dp-day-today\': dt.current}">{{::dt.label}}</span></button></td></tr></tbody></table>',
    );
  }).name;

export default redjade_services;
