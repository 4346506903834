import * as _ from "lodash"

#allows all the elements in the same group to resize in a single throttled call
heightSyncService = (
  $browser
  $window
  $rootScope
) ->
  "ngInject"

  throttles = {}

  resize = (syncGroupId) ->
    maxHeight = Math.max.apply(null, $("[height-sync='#{syncGroupId}']").map(->$(this).height()).get())
    $("[height-sync='#{syncGroupId}']").each( -> $(this).parent().height(maxHeight))

  throttle = (syncGroupId) ->
    unless(throttles[syncGroupId])
      throttles[syncGroupId] = _.throttle(
        ->  resize(syncGroupId)
      , 50)
    throttles[syncGroupId]()

  $rootScope.$on('heightSync', (event, args) -> throttle(args.syncGroupId))


export default heightSyncService
