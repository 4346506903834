import * as _ from "lodash";

const participantChallengeSvc = function (Restangular, LocationSvc) {
  "ngInject";

  const svc = {
    all(location_id) {
      return Restangular.one("locations", location_id).getList("participant_challenges");
    },
    one(location_id, id) {
      return Restangular.one("locations", location_id).one("participant_challenges", id);
    },
  };

  return svc;
};

export default participantChallengeSvc;
