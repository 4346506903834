const SampleSchemaSvc = function (Restangular, $stateParams) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("sample_schemas");
    },
    one(id) {
      return Restangular.one("sample_schemas", id);
    },
  };

  return svc;
};

export default SampleSchemaSvc;
