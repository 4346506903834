import * as _ from "lodash"

currencyName = (
  CURRENCIES
) ->
  "ngInject"

  (key) ->
    currency = _.find(CURRENCIES, (c) -> c.key == key)
    return 'UNKNOWN CURRENCY' unless currency
    currency.value + if currency.symbol then " (#{currency.symbol})" else ''
export default currencyName

