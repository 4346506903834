import * as _ from "lodash"

servingSampleCode = (
) ->
  "ngInject"


(serving_samples) ->
  codes = _.map(serving_samples, (ss) -> ss.code).join(', ')
export default servingSampleCode

