import * as angular from "angular"

wizardNav = (
) ->
  "ngInject"

  {
    restrict: 'EA'
    replace: true
    scope:
      cfg: '='
      wizardProgress: '='
    templateUrl: 'rj_shared/wizard_nav.html'
    controller: 'wizardNavCtrl'
    link: ($scope, element, attrs, ctrl) ->
      ctrl.buildSteps($scope.wizardProgress)

      $scope.$watch('wizardProgress', (new_progress, old_progress) ->
        if !angular.equals(new_progress, old_progress)
          ctrl.buildSteps(new_progress)
      , true)

      return
  }

export default wizardNav

