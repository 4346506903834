scrolly = (
) ->
  "ngInject"

  restrict: "A"
  scope:
    downcallback: "&"
    upcallback: "&"
  link: (scope, element, attrs) ->
    scroll_dist = parseInt(attrs.scrolly) || 300
    scope['is_active'] = false
    $(document).bind "scroll", ->
      if (($(element).offset().top - window.scrollY) < scroll_dist)
        if !scope.is_active
          scope.$apply () ->
            scope.downcallback() unless scope.is_active
          scope.is_active = true
      else if (($(element).offset().top - window.scrollY) > scroll_dist)
        if scope.is_active
          scope.$apply () ->
            scope.upcallback() if scope.is_active
          scope.is_active = false
      return

    return

export default scrolly

