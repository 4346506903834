const previousStateHelper = function ($previousState, $state, stateHelper, $rootScope) {
  "ngInject";

  let previous;
  let last_previous = (previous = null);

  $rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams) {
    last_previous = previous;
    previous = stateHelper.getDisplayName(fromState);
  });

  $rootScope.$on("$stateChangeError", function () {
    previous = last_previous;
  });

  const svc: any = {};

  const MEMO_KEY = "wizard_step";
  const memoNames = {};

  svc.memo = function () {
    $previousState.memo(MEMO_KEY);
    memoNames[MEMO_KEY] = svc.getPreviousName();
  };
  svc.memoIfNone = function () {
    if (!$previousState.get(MEMO_KEY)) {
      return svc.memo();
    }
  };
  svc.forget = function () {
    $previousState.forget(MEMO_KEY);
    delete memoNames[MEMO_KEY];
  };

  svc.getUpName = () => stateHelper.getDisplayName(stateHelper.getFirstRealParent());

  svc.goUp = () => $state.go(stateHelper.getFirstRealParent());

  // get the previous state in order:
  // 1) a current_flow (dashboard, wizard) memo
  // 2) previous state
  // 3) first non-abstract parent state
  const get_last = function () {
    const memo = $previousState.get(MEMO_KEY);
    if (memo != null) {
      return memo;
    } else {
      const previous_state = $previousState.get();
      if (previous_state != null && previous_state.state.name !== "") {
        return previous_state;
      } else {
        return stateHelper.getFirstRealParent();
      }
    }
  };

  svc.getPreviousName = function () {
    if (memoNames[MEMO_KEY] != null) {
      return memoNames[MEMO_KEY];
    } else {
      if (previous != null) {
        return previous;
      } else {
        return svc.getUpName();
      }
    }
  };

  svc.goPrevious = function () {
    const last = get_last();
    if (last.state != null) {
      // came out of $previousState so we can use params
      return $state.go(last.state.name, last.params);
    } else {
      $state.go(last.name);
      return $state.reload();
    }
  };

  return svc;
};

export default previousStateHelper;
