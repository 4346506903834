const imageUrlHelper = function(
  appInfo
) {
  "ngInject";

  return {
    getImageUrl(url) {
      let result;
      const amazon_url = "https://s3.amazonaws.com";

      if (url) {
        if (url.substring(0, amazon_url.length ) === amazon_url) {
          result = url;
        } else {
          result = `/${url}`;
        }
      } else {
        result = null;
      }

      return result;
    }
  };
};


export default imageUrlHelper;

