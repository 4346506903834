import * as _ from "lodash"
import * as angular from "angular"

variableCache = (
  currentUser
  CompanySvc
) ->
  "ngInject"


  variables = {}

  reset = () ->
    _.each(variables, (value, key) ->
      delete variables[key]
    )
    # variables["reset"] = new Date()
    load()

  load = (local_variables = {}) ->
      angular.extend(variables, local_variables)


  if currentUser.company_id?
    CompanySvc.variables(currentUser.company_id).get().then (result) ->
      _.each(result.plain(), (value, key) ->
        variables[key] = "<span class='builder-variable' uib-tooltip='This is a valid variable that will be replaced during survey delivery.'><span ng-non-bindable>{{#{key}}}</span></span>"
      ) 

  return {
    data: variables
    load: load
    reset: reset
  }


  cache = @variable_cache[question.cid]
  if cache
    vars = if question.sample_id then cache[question.sample_id] else cache

    if question.sample_id
      @variable_cache[question.cid] ||= {}
      @variable_cache[question.cid][question.sample_id] = vars
    else
      @variable_cache[question.cid] = vars

  vars

export default variableCache

