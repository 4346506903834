import * as angular from "angular"

redjade_directives = angular.module('redjade.directives', [])
  .directive('btn', require("./btn.directive").default)
  .directive('calendar', require("./calendar.directive").default)
  .directive('calendarView', require("./calendarView.directive").default)
  .directive('checkbox', require("./checkbox.directive").default)
  .directive('qrCode', require("./qrCode.directive").default)
  .directive('clickOnce', require("./clickOnce.directive").default)
  .directive('elastic', require("./elastic.directive").default)
  .directive('fgLine', require("./fgLine.directive").default)
  .service('heightSyncService', require("./heightSync.service").default)
  .directive('heightSync', require("./heightSync.directive").default)
  .directive('focusMe', require("./focusMe.directive").default)
  .directive('formErrors', require("./formErrors.directive").default)
  .directive('frequencyDistributionSettings', require("./frequencyDistributionSettings.directive").default)
  .directive('globalAlerts', require("./globalAlerts.directive").default)
  .controller('globalAlertsCtrl', require("./globalAlerts.controller").default)
  .directive('googlePlace', require("./googlePlace.directive").default)
  .filter('humanizeConstant', require("./humanizeConstant.filter").default)
  .directive('ifUserCan', require("./ifUserCan.directive").default)
  .directive('importSidebarAffix', require("./importSidebarAffix.directive").default)
  .directive('inputMinMaxLimit', require("./inputMinMaxLimit.directive").default)
  .directive('keypressEvents', require("./keypressEvents.directive").default)
  .directive('miniSidebar', require("./miniSidebar.directive").default)
  .controller('miniSideCtrl', require("./miniSidebar.controller").default)
  .directive('ngRepeatN', require("./ngRepeatN.directive").default)
  .controller('percentScalerCtrl', require("./percentScalar.controller").default)
  .directive('percentScaler', require("./percentScaler.directive").default)
  .directive('percentFormatter', require("./percentFormatter.directive").default)
  .directive('redjadeOnly', require("./redjadeOnly.directive").default)
  .directive('rjColorPicker', require("./rjColorPicker.directive").default)
  .directive('rjTransclude', require("./rjTransclude.directive").default)
  .directive('rjLoader', require("./rjLoader.directive").default)
  .directive('rjOffClick', require("./rjOffClick.directive.ts").rjOffClick)
  .directive('scrollBlur', require("./scrollBlur.directive").default)
  .directive('scrolly', require("./scrolly.directive").default)
  .directive('selectOnClick', require("./selectOnClick.directive").default)
  .directive('sidebar', require("./sidebar.directive").default)
  .directive('sidebarContentTransclude', require("./sidebarContentTransclude.directive").default)
  .directive('sidebarset', require("./sidebarSet.directive").default)
  .controller('sidebarSetCtrl', require("./sidebarSet.controller").default)
  .factory('stepHelper', require("./stepHelper.factory").default)
  .directive('stopPropagate', require("./stopPropagate.directive").default)
  .controller('supportTabCtrl', require("./supportTab.controller").default)
  .directive('svelte', require("./svelteComponent.directive").default)
  .directive('timepickerTimezone', require("./timepickerTimezone.directive").default)
  .directive('toggle', require("./toggle.directive").default)
  .directive('topNav', require("./topNav.directive").default)
  .controller('topNavCtrl', require("./topNav.controller").default)
  .component('trumbowyg', require("./angular-trumbowyg.js").default)
  .directive('typeaheadFocus', require("./typeaheadFocus.directive").default)
  .directive('unique', require("./uniqueDrtv.directive").default)
  .service('variableCache', require("./variableCache.service").default)
  .directive('variableReplacer', require("./variableReplacer.directive").default)
  .directive('wizardNav', require("./wizardNav.directive").default)
  .controller('wizardNavCtrl', require("./wizardNav.controller").default)
  .directive('ngScroll', require("./scroll.directive").default)
  .directive('listEditorWrapper', require("./listEditorWrapper").default)
  .directive('listEditorBuilderWrapper', require("./listEditorBuilderWrapper.directive").default)

  .run(($templateCache) ->
    "ngInject"
    # HTML
    $templateCache.put('directives/angular-trumbowyg.html', require("./angular-trumbowyg.html"))
  )

  .name

export default redjade_directives
