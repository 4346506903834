supportTabCtrl = (
  $scope
  currentUser
  appInfo
  $rootScope
  $timeout
  $state
  $stateParams
  $previousState
  $location
  ngToast
  $uibModal
  $interval
  Restangular
) ->
  "ngInject"


  ping = null
  pusher = null
  channel = null

  $scope.current_user = currentUser
  $scope.protocol = window.location.protocol
  $scope.recipient_notifications = []

  $scope.$state = $state

  $scope.navToFreshDesk = () ->
    alert('naving to fresh')

  $scope.openWidget = () ->
    $uibModal.open(
      templateUrl: 'rj_shared/fresh_desk_widget.html'
      backdrop: true
    ).result.then (result) ->
      return true
export default supportTabCtrl

