import _ from "lodash";
import * as tinycolor from "tinycolor2";

const experienceHelper = function ($window) {
  "ngInject";

  const get_xp_sort = function (xp) {
    if (_.any(xp.samples)) {
      return xp.samples[0].identifier;
    } else {
      return "99";
    }
  };

  const is_discrim = xp =>
    xp.questionnaire_type != null &&
    xp.questionnaire_type.startsWith("discrim") &&
    !xp.questionnaire_type.includes("non_sample");

  const longName = function (xp) {
    if (_.any(xp.samples)) {
      return _(xp.samples)
        .map(s => s.name)
        .value()
        .join(", ");
    } else {
      return "99";
    }
  };

  const longIdentifier = function (xp) {
    if (_.any(xp.samples)) {
      return _(xp.samples)
        .map(s => s.identifier)
        .value()
        .join(", ");
    } else {
      return "99";
    }
  };

  const name = function (xp) {
    if (_.any(xp.samples)) {
      return _(xp.samples)
        .map(s => s.identifier)
        .join(", ");
    } else {
      return "99";
    }
  };

  const foregroundColor = function (color) {
    const res = tinycolor.mostReadable(color, [
      "rgb(134, 124, 124)",
      "rgb(251, 252, 255)", // mostly white
      "rgb(134, 116, 116)",
      "rgb(184, 62, 62)", // reddy brown
      "rgb(68, 75, 103)", // navy blue
      "rgb(71, 56, 200)",
      "rgb(125, 234, 143)",
      "rgb(226, 0, 223)",
    ]);
    return res.toHexString();
  };

  const pad = function (xp) {
    if (!_.any(xp.samples)) {
      return null;
    }
    return padSample(xp.samples[0]);
  };

  const padGeneric = function (n, width, z = "0") {
    n = n + "";
    if (n.length >= width) {
      return n;
    } else {
      return new Array(width - n.length + 1).join(z) + n;
    }
  };

  var padSample = sample => padGeneric(sample.identifier, 8);

  return {
    get_xp_sort,
    is_discrim,
    longName,
    longIdentifier,
    name,
    foregroundColor,
    pad,
  };
};

export default experienceHelper;
