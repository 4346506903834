timepickerTimezone = (
  $parse
  moment
) ->
  "ngInject"

  restrict: 'A'
  require: '?ngModel'


  link: (scope, element, attr, ctrls) ->
    unless typeof(moment) == "function"
      throw "timepickerTimezone: moment could not be found, moment is a dependency of this directive"
    local_offset = null
    event_offset = null
    diff_in_minutes = null
    ngModelController = ctrls
    scope.$watch attr.timepickerTimezone, (data) ->
      if data
        local_offset = moment().utcOffset()
        event_offset = moment().tz(data).utcOffset()
        diff_in_minutes = local_offset - event_offset
    # called with a JavaScript Date object when picked from the datepicker
    ngModelController.$parsers.push (viewValue) ->
      # undo the timezone adjustment we did during the formatting
      viewValue.setMinutes viewValue.getMinutes() + diff_in_minutes
      viewValue
      # we just want a local date in ISO format
      #viewValue.toISOString().substring 0, 10
    # called with a 'yyyy-mm-dd' string to format
    ngModelController.$formatters.push (modelValue) ->
      if !modelValue
        return undefined
      # date constructor will apply timezone deviations from UTC (i.e. if locale is behind UTC 'dt' will be one day behind)
      dt = new Date(modelValue)
      # 'undo' the timezone offset again (so we end up on the original date again)
      dt.setMinutes dt.getMinutes() - diff_in_minutes
      dt
    return

export default timepickerTimezone

