const LinkShareSvc = function (Restangular, $stateParams) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("link_shares");
    },
    one(id) {
      return Restangular.one("link_shares", id);
    },
    current() {
      return {
        get() {
          return Restangular.all("link_shares").customGET("current");
        },
      };
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {
        state: null,
        state_params: {},
        access_key: null,
        owner_id: null,
        owner_type: null,
      };
      const data = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, data, "link_shares", false);
    },
  };

  return svc;
};

export default LinkShareSvc;
