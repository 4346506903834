const ProjectCommentSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("project_comments");
    },
    one(id) {
      return Restangular.one("project_comments", id);
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      if (!$.isNumeric(attrs.project_id)) {
        throw "project_id is required";
      }
      const new_attrs = { text: null };
      const data = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, data, "project_comments", false);
    },
  };

  return svc;
};

export default ProjectCommentSvc;
