tinycolor = require("tinycolor2")

tagModalCtrl = (
  taggable
  taggable_type
  $uibModalInstance
  TagSvc
  Restangular
  currentUser
  messageHelper
  ngToast
  $scope
) ->
  "ngInject"

  init = ->
    initNewTag()
    loadTags()
    TagSvc.all().customGET('categories').then (result) ->
      vm.categories = result

  loadTags = (term)->
    vm.tag_filter_info.search_term = term
    obj = _.clone(vm.tag_filter_info)
    obj['scope'] = 'custom'
    TagSvc.all().getList(obj).then (result) ->
      vm.tags = _.map result, (t) -> t.plain()


  addTag = (tag) ->
    Restangular.all('taggings').customPOST({taggable_type: taggable_type, taggable_id: vm.taggable.id, tag_id: tag.id}).then (result) ->
      vm.taggable.tags.push(tag)
      vm.selected_tag = null

  removeTag = (tag) ->
    Restangular.all('taggings/remove').customPOST({taggable_type: taggable_type, taggable_id: vm.taggable.id, tag_id: tag.id}).then (result) ->
      vm.taggable.tags = _.without(vm.taggable.tags, tag)

  cancel = ->
    $uibModalInstance.dismiss()

  withoutAssignedTags = (tag) ->
    assigned_tag_ids = _.map(vm.taggable.tags, 'id')
    !assigned_tag_ids.includes(tag.id)

  customTags = (tags) ->
    _.filter tags, (tag) -> tag.category_type == 'custom'

  initNewTag = ->
    vm.tag = TagSvc.new({company_id: currentUser.company_id})

  createTag = ->
    TagSvc.all().post(vm.tag).then ((result) ->
      addTag(result)
      initNewTag()
      return
    ), (error) ->
      ngToast.danger content: messageHelper.firstMessage(error.data.messages)

  calculateColor = (tag) ->
    res = tinycolor.mostReadable(tag.bg_color, [
      'rgb(134, 124, 124)'
      'rgb(251, 252, 255)' # mostly white
      'rgb(134, 116, 116)'
      'rgb(184, 62, 62)'   # reddy brown
      'rgb(68, 75, 103)'   # navy blue
      'rgb(71, 56, 200)'
      'rgb(125, 234, 143)'
      'rgb(226, 0, 223)'
    ])
    tag.color = res.toHexString()


  vm =
    tag: null
    tags: []
    categories: []
    tag_filter_info:
      search_term: ''
      page: 1
      per: 10

    mode: 'select'
    addTag: addTag
    removeTag: removeTag
    createTag: createTag
    calculateColor: calculateColor
    loadTags: loadTags
    taggable: taggable
    cancel: cancel
    withoutAssignedTags: withoutAssignedTags
    customTags: customTags
  $scope.vm = vm
  init()
  return

export default tagModalCtrl
