const BlindingCodeSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("blinding_codes");
    },

    one(id) {
      return Restangular.one("blinding_codes", id);
    },

    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {};
      const obj = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, obj, "blinding_codes");
    },
  };

  return svc;
};

export default BlindingCodeSvc;
