const CompanySvc = function (Restangular, $stateParams) {
  "ngInject"

  const svc = {
    all() {
      return Restangular.all("companies")
    },
    one(id) {
      return Restangular.one("companies", id)
    },
    updateSettings(company) {
      return company.customPUT(company, "update_recruiting_settings")
    },
    variables(id) {
      return {
        get(params) {
          if (params == null) {
            params = {}
          }
          return Restangular.one("companies", id).customGET("variables", params)
        },
      }
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {}
      }
      const new_attrs = {
        name: "",
        email: "",
        phone_country_code: "",
        phone_area_code: "",
        phone_number: "",
        media_upload_tier: "premium",
      }
      const obj = jQuery.extend(true, new_attrs, attrs)
      return Restangular.restangularizeElement(null, obj, "companies")
    },
    search(search_term, page, per) {
      if (page == null) {
        page = 1
      }
      if (per == null) {
        per = 10
      }
      const params = {
        search_term,
        page,
        per,
      }
      return Restangular.all("companies").customPOST(params, "search")
    },
    usageMetricsDownload() {
      return {
        get(company_id, start_date, end_date, report_type) {
          if (report_type == null) {
            report_type = "summary"
          }
          return Restangular.all("metrics").customGET("usage_metrics_download", {
            company_id,
            start_date,
            end_date,
            report_type,
          })
        },
      }
    },
    recentUsersMetrics() {
      return {
        get(company_id, start_date, end_date) {
          return Restangular.all("metrics").customGET("user_login_metrics", { company_id, start_date, end_date })
        },
      }
    },
    recentUsersMetricsDownload() {
      return {
        get(company_id, start_date, end_date) {
          return Restangular.all("metrics").customGET("user_login_metrics_download", {
            company_id,
            start_date,
            end_date,
          })
        },
      }
    },
    userRoleMetricsDownload() {
      return {
        get(company_id) {
          return Restangular.all("metrics").customGET("user_role_metrics_download", { company_id })
        },
      }
    },
  }

  return svc
}

export default CompanySvc
