const AnalysisQuestionSvc = function (Restangular) {
  "ngInject"

  Restangular.extendModel("analysis_questions", function (questionnaire) {
    if (questionnaire && typeof questionnaire === "object") {
      questionnaire.recruitParticipantCount = function () {
        if (!questionnaire.needed_participant_count || !questionnaire.padding_ratio) {
          return 0
        }
        return questionnaire.needed_participant_count * questionnaire.padding_ratio
      }
    }
    return questionnaire
  })

  const svc = {
    all() {
      return Restangular.all("analysis_questions")
    },
    one(id) {
      return Restangular.one("analysis_questions", id)
    },
  }

  return svc
}

export default AnalysisQuestionSvc
