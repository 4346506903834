dialogCtrl =
(
  $scope
  $uibModalInstance
  settings
  $sce
) ->
  "ngInject"

  vm = {}
  vm.style = settings.style || 'warning'
  vm.ok_button = settings.ok_button || 'OK'
  vm.cancel_button = settings.cancel_button || 'cancel'
  vm.hide_footer = settings.hide_footer || false
  vm.title = settings.title
  vm.message = $sce.trustAsHtml(settings.message)
  vm.showTextEdit = settings.showTextEdit || false
  vm.textEditModel = if vm.showTextEdit then settings.textEditModel else null
  vm.messageFooter = $sce.trustAsHtml(settings.messageFooter)
  vm.messageFooterTheme = settings.messageFooterTheme

  if settings.hide_cancel != undefined
    vm.hide_cancel = settings.hide_cancel
  else
    vm.hide_cancel = false

  vm.submit = ->
    value = true
    if vm.showTextEdit
      value = vm.textEditModel
    $uibModalInstance.close value
    return

  vm.cancel = ->
    $uibModalInstance.close false
    return

  $scope.vm = vm
  return

export default dialogCtrl
