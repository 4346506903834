# replace input value with min/max if user types out of range
inputMinMaxLimit = (
) ->
  "ngInject"

  return {
    link: ($scope, element, attributes) ->
      element.on 'keyup', (e) -> # don't let them type outside the min/max range
        if Number(element.val()) > Number(attributes.max) and e.keyCode != 46 and e.keyCode != 8
          e.preventDefault()
          element.val attributes.max
        else if Number(element.val()) < Number(attributes.min) and e.keyCode != 46 and e.keyCode != 8
          e.preventDefault()
          element.val attributes.min
      element.on 'keydown', (e) -> # remove scientific notation -> 4e27
        keyCode = if 'which' of event then event.which else event.keyCode
        !(keyCode == 69 or keyCode == 101)
  }


export default inputMinMaxLimit

