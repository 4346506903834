const QuestionTemplateSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("question_templates");
    },
    one(id) {
      return Restangular.one("question_templates", id);
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {
        title: "",
        question_template_folder_id: null,
        company_id: null,
        question_type: null,
        text: {},
        answers: [],
        config: {},
        reporting: {},
        validations: [],
        groups: [],
      };
      const qtemp = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, qtemp, "question_templates");
    },
  };

  return svc;
};

export default QuestionTemplateSvc;
