date_string = (
) ->
  "ngInject"

  (value) ->
    #moment().format("MMMM Do YYYY, h:mm");
    value = value.split("+")[0]  if value.indexOf("+") > 0
    m = moment(value)
    m.format "LLLL"

export default date_string

