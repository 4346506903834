const DocumentSvc = function (Restangular) {
  "ngInject";

  Restangular.extendModel("documents", doc => doc);
  const svc = {
    all() {
      return Restangular.all("documents");
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {};
      const obj = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, obj, "documents");
    },
  };

  return svc;
};

export default DocumentSvc;
