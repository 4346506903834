// scope.scroll is a reference to a function, not a function itself. It is called with scope.scroll()(your_arguments_here)
const scroll = function () {
  return {
    restrict: "A",
    scope: {
      scroll: "&",
    },
    link: function (scope, elem, attrs) {
      elem.on("scroll", function (e) {
        if (scope.scroll && typeof scope.scroll() === "function") {
          scope.scroll()(e)
        }
      })
    },
  }
}

export default scroll
