humanizeString = (
) ->
  "ngInject"

  (text) ->
    if text?
      formatted_text = text.humanize()
    else
      formatted_text = null

    return formatted_text

export default humanizeString
