const fileSaver = require("file-saver");

const CalcsSvc = function (Restangular, $stateParams) {
  "ngInject";

  const svc = {
    one(id) {
      return Restangular.one("calcs", id);
    },
    payload() {
      return Restangular.one("calcs/payload");
    },
    download(payload) {
      const filename = `${payload.analysis.project_name}_payload.json`.toLowerCase().replace(/ /, "_");
      const blob = new Blob([JSON.stringify(Restangular.stripRestangular(payload), null, "  ")], {
        type: "application/json",
      });
      fileSaver.saveAs(blob, filename);
    },
  };

  return svc;
};

export default CalcsSvc;
