ctrl = (
  $scope
  $uibModalInstance
  languageHelper
) ->
  'ngInject'
  submit = ->
    $uibModalInstance.close {subject: vm.subject, body: vm.body, locale: vm.locale}

  cancel = ->
    $uibModalInstance.dismiss "cancel"

  vm =
    subject: ''
    body: ''
    locale: 'en_US'
    submit: submit
    cancel: cancel
    languageHelper: languageHelper
  $scope.vm = vm
  return

export default ctrl
