import { guid } from "~angular/src/survey_builder/utils/windowHelpers"
//@ts-ignore
import SvelteExports from "SvelteExports"

const svelteComponent = function ($timeout) {
  "ngInject"
  return {
    restrict: "E",
    require: "",
    scope: {
      component: "@",
      props: "=",
    },
    // "data-sc" means data-svelte-container - this is a parent css selector to help stop style bleed
    template: `<div data-sc id="svelte-{{vm.id}}"></div>`,
    controller: [
      "$scope",
      "$rootScope",
      function ($scope, $rootScope) {
        const vm = {
          id: guid(),
          widget: <any>null,
        }
        $scope.vm = vm
        const attachToTarget = () => {
          if (vm.widget) return
          const target = document.querySelector("#svelte-" + vm.id)
          if (target) {
            vm.widget = new SvelteExports[$scope.component]({
              target,
              props: $scope.props,
            })
            vm.widget.$$.after_update.push(() => {
              $timeout(() => $scope.$apply(), 0)
            })
            if (!$rootScope.loadedSvelte) {
              $rootScope.loadedSvelte = true
            }
          }
        }
        const initComponent = () => {
          $scope.$watch("props", () => {
            if ($scope.props && typeof $scope.props !== "object") {
              throw new Error('svelteComponent must take an object as an argument to "props"!')
            }
            if (!vm.widget) {
              if (SvelteExports[$scope.component] == undefined) {
                // update angular-bridge/svelte-exports.js
                throw new Error(`Svelte component "${$scope.component}" not found, may be missing from exports`)
              }
              attachToTarget()
            } else {
              vm.widget.$$set($scope.props || {})
            }
          })
        }
        $timeout(initComponent)
      },
    ],
  }
}

export default svelteComponent
