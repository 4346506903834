const DemographicCategorySvc = function (Restangular, $q) {
  "ngInject"

  const getTypes = function (sensory_test_id = null) {
    const deferred = $q.defer()
    const list = [{ id: "user_defined", name: "User Defined" }]
    if (sensory_test_id) {
      list.push({ id: "age", name: "Age Range" })
    }
    deferred.resolve(list)
    return deferred.promise
  }

  Restangular.extendModel("demographic_categories", function (category) {
    if (category && typeof category === "object") {
      category.isRange = () => category.category_type === "age" || category.category_type === "household_size"
    }
    return category
  })

  const svc = {
    all(sensory_test_id) {
      return Restangular.all("demographic_categories")
    },
    lookup() {
      return Restangular.all("demographic_categories/lookup")
    },
    onboarding() {
      return Restangular.all("demographic_categories/onboarding")
    },
    getTypes,
    new(attrs) {
      if (attrs == null) {
        attrs = {}
      }
      const new_attrs = {
        name: null,
        id: null,
        demographics: [],
        category_type: "user_defined",
        exclusive: true,
      }

      const me = jQuery.extend(true, new_attrs, attrs)
      return Restangular.restangularizeElement(null, me, "demographic_categories")
    },
  }
  // rangAs: (parent, obj) ->
  //   Restangular.restangularizeElement(parent, obj, "demographic_categories")

  return svc
}

export default DemographicCategorySvc
