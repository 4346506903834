percentage = (
  $filter
) ->
  "ngInject"

  (input, decimals) ->
    if typeof input == 'undefined'
      return
    $filter('number')(input * 100, decimals) + '%'

export default percentage

