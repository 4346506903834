import * as _ from "lodash";

const EventHostingBidSvc = function (Restangular) {
  "ngInject";

  Restangular.addElementTransformer("event_hosting_bids", false, function (element) {
    element.amount = parseFloat(element.amount);
    _.each(element.line_items, function (li) {
      li.quantity = parseInt(li.quantity);
      return (li.cost = parseFloat(li.cost));
    });
    return element;
  });

  const svc = {
    one(id) {
      return Restangular.one("event_hosting_bids", id);
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {
        preapproved: false,
        amount: 0.0,
        line_items: [{ name: "", cost: 0.0, quantity: 1 }],
        detail_type: "advanced",
        currency_type: "USD",
      };
      const model = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, model, "event_hosting_bids");
    },
  };
  return svc;
};

export default EventHostingBidSvc;
