const PanelSubjectSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("panel_subjects");
    },
    one(id) {
      return Restangular.one("panel_subjects", id);
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {
        panel_id: null,
        subject_id: null,
      };
      const obj = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, obj, "panel_subjects");
    },
  };

  return svc;
};

export default PanelSubjectSvc;
