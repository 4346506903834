// THIS CONTROLLER EXISTS SOLELY TO PUT ANGULAR OBJECTS ON THE WINDOW SO SVELTE CAN USE THEM! DO NOT DO ANYTHING IMPORTANT HERE!

declare global {
  interface Window {
    PusherKey: {
      calculate: Function
    }
    PusherAuth: {
      calculate: Function
    }
    Pusher: any
  }
  const RjEvent: typeof import("~svelte/lib/event/RjEvent")["default"]
  // "~svelte/static/locales/languages.json")

  const Pusher: any
}
// @ts-ignore - we still need this for the moment
window.Pusher = require("pusher-js")

const topNavCtrl = function (
  $rootScope,
  appInfo,
  appStateSvc,
  $state,
  $stateParams,
  $previousState,
  $scope,
  $timeout,
  $location,
  currentUser,
  Restangular,
) {
  "ngInject"
  // @ts-ignore
  window.angularJS = {
    $rootScope,
    $state,
    $stateParams,
    $previousState,
    $timeout,
    $location,
    appInfo,
    appStateSvc,
    Restangular,
  }
  $scope.currentUser = currentUser
  $scope.appStateSvc = appStateSvc
  $scope.$state = $state
  $scope.$stateParams = $stateParams
  $scope.currentUser = currentUser
  $scope.appInfo = appInfo
  $scope.$previousState = $previousState

  // convert angular events to svelte ones
  setTimeout(() => {
    $scope.$on("$stateChangeSuccess", (a, b) => {
      RjEvent.emit("state-change-success")
    })
    $scope.$on("$locationChangeSuccess", (event, url) => {
      RjEvent.emit("location-change-success", { event, url })
    })
  })
}

export default topNavCtrl
