preferredTime = (
) ->
  "ngInject"

  (time, campaign = null) ->
    return unless moment(time).isValid()

    if campaign && campaign.time_format
      if campaign.time_format == '24hour'
        time_format = "HH:mm"
      else
        time_format = "hh:mm A"
    else if current_user.time_format
      if current_user.time_format == '24hour'
        time_format = "HH:mm"
      else
        time_format = "hh:mm A"
    else
      time_format = "hh:mm A"

    return moment(time).format(time_format)
export default preferredTime

