// breadth-first search that continuously expands length of loop as it traverses the object
// forms an array of paths to each property until the desired key is found, then returns that path
// returns nothing if no property is found
export function findPathOfNestedProperty(objs: Array<Object>, property: string) {
    const paths : any[] = []
    let newObjs : any[] = []
    for (let i = 0; i < objs.length; i++) {
      let obj = objs[i]
      const keys = Object.keys(obj);
      for (let key of keys) {
        let path : any = paths.find((p: any) => p.includes(key))
        if (!path) {
         path = []
         path.push(key)
        }
        if (key == property) 
          return path;
        if (obj[key] && typeof obj[key] == 'object') {
          newObjs.push(obj[key]);
          for (let newKey of Object.keys(obj[key])) {
            const newPath = [...path, newKey];
            if(newKey == property) {
              return newPath
            }
            paths.push(newPath)
          }
        } 
      }
        if(i == objs.length - 1) {
        objs.push(...newObjs);
        newObjs = []
      }
    }
    return []
  }

export function setNestedProperty(obj: Object, path: string | string[], val: any) {
  let cur = obj;
  let strSplit;
  if (typeof path == 'string') {
    strSplit = path.split('.');
  } else if (Array.isArray(path)) {
    strSplit = path;
  } 
  for (let i = 0; i < strSplit.length; i++) {
    const key = strSplit[i];
    if (i == strSplit.length - 1) {
      cur[key] = val;
    } else  {
      cur = cur[key];
    }
  }
  return obj
}	

export function getNestedProperty(obj: Object, path: string | string[]) {
  let cur = obj;
  let strSplit;
  if (typeof path == 'string') {
    strSplit = path.split('.');
  } else if (Array.isArray(path)) {
    strSplit = path;
  } 
  for (let i = 0; i < strSplit.length; i++) {
    const key = strSplit[i];
    if (!cur) {
      return null;
    }
    if (i == strSplit.length - 1) {
      return cur[key];
    }
    cur = cur[key];
  }
  return null;
}	
   
export function textFromArrayOfObjects (arr : any[], path: string | string[]) {
  let text = "";
  for (let i = 0; i < arr.length; i++) {
    let newText = getNestedProperty(arr[i], path);
    text += newText ? newText : "";
    if (i < arr.length - 1) {
      text += "\n";
    }
  }
  return text;
}

export function cloneObject(obj) {
  if (obj === null || typeof obj !== "object") 
    return obj;
  const clone = Array.isArray(obj) ? [] : {};
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key))
      clone[key] = cloneObject(obj[key]);
  }
  return clone;
}
