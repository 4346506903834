const RecruitingCampaignSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("recruiting_campaigns");
    },
    one(id) {
      return Restangular.one("recruiting_campaigns", id);
    },
    meta(id, event_id) {
      return this.one(id).customGET("meta");
    },
    lookup() {
      return Restangular.all("recruiting_campaigns/lookup");
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = { exclude_panelists: true, exclude_anonymous_dependents: true };
      const campaign = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, campaign, "recruiting_campaigns");
    },
  };
  return svc;
};

export default RecruitingCampaignSvc;
