const ReservationSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("reservations");
    },
    one(id) {
      return Restangular.one("reservations", id);
    },
    lookup() {
      return Restangular.all("reservations/lookup");
    },
  };

  return svc;
};

export default ReservationSvc;
