const CompanyInvitationSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("company_invitations");
    },

    one(id) {
      return Restangular.one("company_invitations", id);
    },

    process_invitation(token) {
      return Restangular.all("company_invitations");
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {
        email: null,
        token: null,
        result: "pending",
        locale: "en_US",
      };
      const inv = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, inv, "company_invitations", null);
    },
  };
  return svc;
};

export default CompanyInvitationSvc;
