const PanelFavoriteSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("panel_favorites");
    },
  };

  return svc;
};

export default PanelFavoriteSvc;
