const SubjectInteractionSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("subject_interactions");
    },
    outcomes() {
      return Restangular.all("subject_interactions").customGET("outcomes");
    },
    one(id) {
      return Restangular.one("subject_interactions", id);
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {};
      const model = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, model, "subject_interactions");
    },
  };
  return svc;
};

export default SubjectInteractionSvc;
