keypressEvents = (
  $document
  $rootScope
) ->
  "ngInject"

  return (
    restrict: 'A'

    link: (scope, element) ->
      $document.bind 'keydown', (e) ->
        $rootScope.$broadcast 'keypress', e
        return
      return
  )


export default keypressEvents

