const CompanyAdminSvc = function (Restangular, $stateParams) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("admin/company_status");
    },
    one(id) {
      return Restangular.one("admin/company_status", id);
    },
  };

  return svc;
};

export default CompanyAdminSvc;
