import * as angular from "angular"
const moment_timezone = require("moment-timezone")

const redjade_models = angular
  .module("redjade.models", [])
  .constant("moment", moment_timezone)
  .factory("Analysis", require("./analysis.service").default)
  .factory("AnalysisSvc", require("./analysis.service").default)
  .factory("AnalysisQuestionSvc", require("./analysisQuestion.service").default)
  .factory("AnalysisSampleSvc", require("./analysisSample.service").default)
  .factory("AnalysisSettingsSvc", require("./analysisSettings.service").default)
  .factory("AnswerDemographicMappingSvc", require("./answerDemographicMapping.service").default)
  .factory("BlastOptOutsSvc", require("./blastOptOut.service").default)
  .factory("BlastTemplateSvc", require("./blastTemplate.service").default)
  .factory("BlindingCodeSvc", require("./blindingCode.service").default)
  .factory("CalcRequestSvc", require("./calcRequest.service").default)
  .factory("CalcPayloadFilterSvc", require("./calcPayloadFilter.service").default)
  .factory("CalcsSvc", require("./calcs.service").default)
  .factory("CompanyAdminSvc", require("./companyAdmin.service").default)
  .factory("CompanyInvitationSvc", require("./companyInvitation.service").default)
  .factory("CompanyPartnerSvc", require("./companyPartner.service").default)
  .factory("CompanySvc", require("./company.service").default)
  .factory("CompletedQuestionnaireSvc", require("./completedQuestionnaire.service").default)
  .factory("ConsentAgreementVersionSvc", require("./consentAgreementVersion.service").default)
  .factory("DemographicCategorySvc", require("./demographicCategory.service").default)
  .factory("DemographicSvc", require("./demographic.service").default)
  .factory("DocumentSvc", require("./document.service").default)
  .factory("EventHostingBidSvc", require("./eventHostingBid.service").default)
  .factory("EventHostingRequestSvc", require("./eventHostingRequest.service").default)
  .factory("EventSvc", require("./event.service").default)
  .factory("ExperienceSvc", require("./experience.service").default)
  .factory("ExportSettingsSvc", require("./exportSettings.service").default)
  .factory("FieldSvc", require("./field.service").default)
  .factory("FileAttachmentSvc", require("./fileAttachment.service").default)
  .factory("FormFieldSvc", require("./formField.service").default)
  .factory("GlobalAlertSvc", require("./globalAlert.service").default)
  .factory("HouseholdSvc", require("./household.service").default)
  .factory("HtmlToPdfSvc", require("./htmlToPdf.service").default)
  .factory("LinkShareSvc", require("./link_share.service").default)
  .factory("LocationSvc", require("./location.service").default)
  .factory("ModalitySvc", require("./modality.service").default)
  .factory("OauthApplicationSvc", require("./oauthApplication.service").default)
  .factory("OffsitePartnershipSvc", require("./offsitePartnership.service").default)
  .factory("OnboardingConfigurationSvc", require("./onboardingConfiguration.service").default)
  .factory("OnlineEventSvc", require("./onlineEvent.service").default)
  .factory("PageTemplateSvc", require("./pageTempate.service").default)
  .factory("PanelFavoriteSvc", require("./panelFavorite.service").default)
  .factory("PanelPerformanceSettingsSvc", require("./panelPerformanceSettings.service").default)
  .factory("PanelSubjectSvc", require("./panelSubject.service").default)
  .factory("PanelSvc", require("./panel.service").default)
  .factory("ParticipantGroupSvc", require("./participantGroup.service").default)
  .factory("ParticipantServingSvc", require("./participantServing.service").default)
  .factory("ParticipantSvc", require("./participant.service").default)
  .factory("ProductSvc", require("./product.service").default)
  .factory("ProjectCommentSvc", require("./projectComment.service").default)
  .factory("ProjectSvc", require("./project.service").default)
  .factory("QuestionSvc", require("./question.service").default)
  .factory("QuestionTemplateFolderSvc", require("./questionTemplateFolder.service").default)
  .factory("QuestionTemplateSvc", require("./questionTemplate.service").default)
  .factory("QuestionnaireSvc", require("./questionnaire.service").default)
  .factory("QuestionnaireTemplateSvc", require("./questionnaireTemplate.service").default)
  .factory("QuotaSvc", require("./quota.service").default)
  .factory("RecipientNotificationSvc", require("./recipientNotification.service").default)
  .factory("RecruitingBlastRecipientSvc", require("./recruitingBlastRecipient.service").default)
  .factory("RecruitingBlastReportSvc", require("./recruitingBlastReport.service").default)
  .factory("RecruitingBlastSvc", require("./recruitingBlast.service").default)
  .factory("RecruitingCampaignSvc", require("./recruitingCampaign.service").default)
  .factory("RecruitingDemographicCategorySvc", require("./recruitingDemographicCategory.service").default)
  .factory("RecruitingTaskSvc", require("./recruitingTask.service").default)
  .factory("RegionSvc", require("./region.service").default)
  .factory("ReservationSvc", require("./reservation.service").default)
  .factory("RoomSvc", require("./room.service").default)
  .factory("SampleSchemaSvc", require("./sampleSchema.service").default)
  .factory("SampleSvc", require("./sample.service").default)
  .factory("ScreenerSvc", require("./screener.service").default)
  .factory("SensoryTestSvc", require("./sensoryTest.service").default)
  .factory("SessionReminderSvc", require("./sessionReminder.service").default)
  .factory("SessionSvc", require("./session.service").default)
  .factory("StationActivationCodeSvc", require("./stationActivationCode.service").default)
  .factory("StationSvc", require("./station.service").default)
  .factory("ParticipantChallengeSvc", require("./participantChallenge.service").default)
  .factory("SubjectConsentResponseSvc", require("./subjectConsentResponse.service").default)
  .factory("SubjectDemographicSvc", require("./subjectDemographic.service").default)
  .factory("SubjectInteractionSessionSvc", require("./subjectInteractionSession.service").default)
  .factory("SubjectInteractionSvc", require("./subjectInteraction.service").default)
  .factory("SubjectSvc", require("./subject.service").default)
  .factory("TagSvc", require("./tag.service").default)
  .factory("TeamSvc", require("./team.service").default)
  .factory("UserSvc", require("./user.service").default)
  .factory("VariableSvc", require("./variable.service").default).name

export default redjade_models
