
sidebarset = (
) ->
  "ngInject"

  {
    restrict: 'EA'
    transclude: true
    replace: true
    scope:
      type: '@'
      homeSref: "&"

    controller: 'sidebarSetCtrl'
    templateUrl: 'rj_shared/sidebarset.html'
    link: (scope, element, attrs, sidebarSetCtrl) ->
      if scope.$eval(scope.homeSref)
        attrs['homeSref'] = scope.$eval(scope.homeSref)

      sidebarSetCtrl.setOptions attrs

      # scope.vertical = if angular.isDefined(attrs.vertical) then scope.$parent.$eval(attrs.vertical) else false
      # scope.justified = if angular.isDefined(attrs.justified) then scope.$parent.$eval(attrs.justified) else false
      return

  }

export default sidebarset

