import * as angular from "angular"

importSidebarAffix = (
  $window
  $timeout
  $document
) ->
  "ngInject"

  restrict: "A"
  scope: true
  link: (scope, element, attrs) ->
    top_offset = parseInt(attrs.topOffset)
    bottom_offset = parseInt(attrs.bottomOffset)
    scope['is_active'] = false

    setMaxHeight = ->
      width = $($window).width()

      if width < 991
        element.css('max-height', '')
      else
        # THIS IS SUPER SPECIFIC TO imports
        height = $($window).height() - 155
        element.css('max-height', height + 'px')

    setMaxHeight()
    $window.addEventListener("resize", setMaxHeight)

    grabWidth = ->
      # return unless scope.is_active
      parent_width = angular.element(element.parents()[0]).width()
      element.css('width', parent_width)

    $window.addEventListener("resize", grabWidth)

    $document.bind "scroll", ->
      below_top_offset = $window.scrollY > top_offset
      scope.width = element.width()

      if below_top_offset && !scope.is_active
        element.addClass("affix")
        element.css('width', scope.width + 'px')
        scope.is_active = true
      else if ($window.scrollY < bottom_offset  && scope.is_active) || (!below_top_offset && scope.is_active)
        element.removeClass("affix")
        element.css('width', 'auto')
        scope.is_active = false

      return

    return

export default importSidebarAffix

