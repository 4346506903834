const Urls = {
  protocol() {
    return window.location.protocol;
  },
  is_secure() {
    return window.location.protocol === "https:";
  },
  host() {
    return window.location.host;
  },
  cookieDomainUrl() {
    return "." + process.env.BASE_URL;
  },
  enforceClientUrl(company_client_url) {
    if (window.location.host === "host.docker.internal") {
      return false;
    }
    if (!company_client_url) {
      return false;
    } // avoid redirect loops
    if (company_client_url !== window.location.origin) {
      // @ts-ignore
      window.location = company_client_url + window.location.pathname + window.location.search;
      return true;
    }
    return false;
  },
};

export default Urls;
