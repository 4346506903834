import * as _ from "lodash"

const HouseholdSvc = function (Restangular) {
  "ngInject"

  Restangular.extendModel("households", function (obj) {
    if (obj && typeof obj === "object") {
      obj.name = function (subject_id) {
        const cohabs = _.reject(_.clone(obj.subjects), s => s.id === subject_id)
        if (cohabs.length === 0) {
          return "Empty household"
        } else if (cohabs.length === 1) {
          return `${cohabs[0].name}'s household`
        } else if (cohabs.length <= 2) {
          return `${cohabs[0].name} and ${cohabs[1].name}'s household`
        } else {
          return `${cohabs[0].name}, ${cohabs[1].name} and ${cohabs.length - 2} others household`
        }
      }
    }
    return obj
  })

  const svc = {
    all() {
      return Restangular.all("households")
    },
    one(id) {
      return Restangular.one("households", id)
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {}
      }
      const new_attrs = {}
      const event = jQuery.extend(true, new_attrs, attrs)
      return Restangular.restangularizeElement(null, event, "households")
    },
  }

  return svc
}
export default HouseholdSvc
