const QuotaSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("quotas");
    },
    one(quota_id) {
      return Restangular.one("quotas", quota_id);
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {};
      const plan = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, plan, "quotas");
    },
  };
  return svc;
};

export default QuotaSvc;
