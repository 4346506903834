import _ from "lodash";

if (typeof String.prototype.startsWith !== "function") {
  String.prototype.startsWith = function (str) {
    return this.indexOf(str) === 0;
  };
}

if (typeof String.prototype.includes !== "function") {
  String.prototype.includes = function (str) {
    return this.indexOf(str) > -1;
  };
}

if (typeof String.prototype.endsWith !== "function") {
  String.prototype.endsWith = function (suffix) {
    return this.indexOf(suffix, this.length - suffix.length) !== -1;
  };
}

// @ts-ignore
if (typeof String.prototype.humanize !== "function") {
  // @ts-ignore
  String.prototype.humanize = function () {
    const words = this.toLowerCase().replace(/[_:]+/g, " ").split(" ");
    const humanized = _(words)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .reduce((a, b) => `${a} ${b}`);
    return humanized;
  };
}
