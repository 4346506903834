const RegionSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("regions");
    },
    one(id) {
      return Restangular.one("regions", id);
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {
        sensory_test_id: null,
        code: null,
        name: null,
      };
      const region = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, region, "regions", null);
    },
  };
  return svc;
};

export default RegionSvc;
