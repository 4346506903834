const SessionSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("sessions");
    },
    one(id) {
      return Restangular.one("sessions", id);
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {};
      const model = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, model, "sessions");
    },
  };
  return svc;
};

export default SessionSvc;
