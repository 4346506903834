rjColorPicker = (
) ->
  "ngInject"

  restrict: 'E'
  require: 'ngModel'
  scope: {
          ngModel: '=',
          isDisabled:'='
        }
  template:  '<input type="color" ng-model="model" class="rj-color-picker" ng-disabled="isDisabled">'

  link: (scope, element, attrs, ngModel) ->

    # hard colors
    # default_colors = ['#005e0f','#b83ef1','#9cff45','#4800a2','#f0ff2c','#6a59ff','#01c743','#ff1ccb','#02e281','#de01aa',
    # '#54a100','#366eff','#ffd001','#0057b6','#d5bc00','#7d007b','#c1ff80','#002569','#ffbb29','#0075ca','#f98300',
    # '#6ba9ff','#a39b00','#ff87e0','#00901e','#d5004a','#a0ffad','#5a004d','#e3ff96','#001e42','#ffef81','#1d0019',
    # '#a7ffd1','#b1004d','#01a864','#ff6781','#003e01','#e9c2ff','#5f7d00','#abb0ff','#a61d00','#54dbff','#ff7147',
    # '#01bbc3','#7c000d','#dbfff0','#060001','#ffedba','#460024','#ffcb7d','#00476d','#736300','#c6daff','#640016',
    # '#ffeff3','#002b00','#ff94b9','#1c2800','#ffcfd2','#002c24','#73003c','#018169','#501d00','#413000']

    # soft colors
    # default_colors = ['#d980d2','#6fe744','#5437ca','#bcec3f','#a044db','#66e572','#d84fdb','#67bb34','#e438ae','#e7dc35',
    # '#6362da','#aabd2d','#492288','#d8dd6a','#9d5cc4','#45a34e','#a53496','#62e09e','#e14f95','#b4e182','#341953','#e6b03a',
    # '#4677dc','#e6482b','#5fddc9','#da3b5b','#53986d','#ac3968','#759530','#9887e2','#a3912c','#4a4f96','#de7e33','#668ed2',
    # '#b13d26','#93dbe2','#792e24','#a9d7a6','#752b64','#e7d08c','#1d2537','#c0873f','#5fa1cc','#965427','#b4c5e8','#386423',
    # '#d4a8e2','#233723','#dd849e','#5ca1a5','#e07a6a','#43667b','#a0a162','#423f63','#cecbb2','#3f1923','#c4a8ba','#514123',
    # '#906c9e','#7b6a35','#843748','#58715c','#c69880','#7f5d66']

    # sample colors
    default_colors = ['#E8BF51' ,'#F05C1A' ,'#0D9D1E' ,'#CDCDCD' ,'#0D5E9D' ,'#DE0003' ,'#6E6E6E' ,'#38DA4B'
    ,'#70AFE0' ,'#EF7173' ,'#F19319' ,'#0048DB' ,'#FFCBA4' ,'#FFCDED' ,'#03510C' ,'#D7A1E4' ,'#7A0305'
    ,'#0DBA8F' ,'#FFD737' ,'#CBED83' ,'#C5E3FA' ,'#EAADE2' ,'#6C3D28' ,'#A2F2DE' ,'#A9E42D' ,'#5500A4'
    ,'#F7EAB5' ,'#CA2500' ,'#A6ECAE' ,'#57E916' ,'#84C40E' ,'#F23119' ,'#018562' ,'#75AD5A' ,'#AB06CB' ,'#29250B'
    ,'#A02E1B' ,'#FDCBA1' ,'#F2ABF7' ,'#D9F920' ,'#C50634' ,'#66AFAD' ,'#F23E45' ,'#2A8272' ,'#AAA857' ,'#62FE37'
    ,'#1BA86C' ,'#8487F5' ,'#DD795B' ,'#EFFFA5' ,'#BED99E' ,'#F9E887' ,'#4FD661' ,'#D8F597' ,'#BB7B6C' ,'#3FDE7F']


    # original not 64
    # default_colors = [
    #   '#3182bd','#6baed6','#9ecae1','#c6dbef','#e6550d','#fd8d3c','#fdae6b','#fdd0a2','#31a354','#74c476','#a1d99b',
    #   '#c7e9c0','#756bb1','#9e9ac8','#bcbddc','#dadaeb','#636363','#969696','#bdbdbd','#d9d9d9','#1f77b4','#aec7e8',
    #   '#ff7f0e','#ffbb78','#2ca02c','#98df8a','#d62728','#ff9896','#9467bd','#c5b0d5','#8c564b','#c49c94','#e377c2',
    #   '#f7b6d2','#7f7f7f','#c7c7c7','#bcbd22','#dbdb8d','#17becf','#9edae5','#393b79','#5254a3','#6b6ecf','#9c9ede',
    #   '#637939','#8ca252','#b5cf6b','#cedb9c','#8c6d31','#bd9e39','#e7ba52','#e7cb94','#843c39','#ad494a','#d6616b',
    #   '#e7969c','#7b4173','#a55194','#ce6dbd','#de9ed6'
    # ]

    if scope.customColors
      scope.colors = scope.customColors
    else
      scope.colors = default_colors

    scope.row_length = Math.ceil(Math.sqrt(scope.colors.length))

    scope.groups = scope.colors.map((e, i) ->
      if i % scope.row_length == 0 then scope.colors.slice(i, i + scope.row_length) else null
    ).filter((e) ->
      e
    )

    scope.$watch 'model', (value) ->
      ngModel.$setViewValue value
      return

    scope.setColor = (color) ->
      scope.model = color

    ngModel.$render = ->
      if !ngModel.$viewValue
        ngModel.$viewValue = '#efefef'
      scope.model = ngModel.$viewValue
      return

export default rjColorPicker

