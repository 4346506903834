import * as _ from "lodash"

ifUserCan = (
  currentUser
) ->
  "ngInject"

  return (
    scope:
      model: '@ifUserCan'

    link: (scope, element) ->
      unless _.includes currentUser.clearance_levels, scope.model
        element.remove()
      return
  )


export default ifUserCan

