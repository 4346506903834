const stateHelper = function ($interpolate, $state, $parse, $rootScope) {
  "ngInject";

  const svc: any = {};

  svc.getDisplayName = function (state) {
    const displayName = $parse("data.displayName")(state);
    if (displayName === "undefined") {
      return state.name;
    } else {
      const internal_state = state.$$state();
      const interpolationContext = internal_state.locals.globals;
      return $interpolate(displayName)(interpolationContext);
    }
  };

  // todo: make this recursive
  svc.getFirstRealParent = function () {
    let { parent } = $state.$current;
    if (parent.abstract) {
      ({ parent } = parent);
    }
    return parent;
  };

  var getFirstParentLocals = function (internal_state) {
    if (internal_state.locals != null) {
      return internal_state.locals.globals;
    } else if (internal_state.parent != null) {
      return getFirstParentLocals(internal_state.parent);
    } else {
      return "undefined";
    }
  };
  return svc;
};

export default stateHelper;
