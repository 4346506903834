import * as _ from "lodash"

stepHelper = (
  $state
) ->
  "ngInject"


  buildSteps = (wizard_cfg, progress) ->
    unless progress
      progress = {}
      step_ids = Object.keys(wizard_cfg)
      _.each(step_ids, (step) ->
        progress[step] = 'invalid'
        return
      )

    step_ids = Object.keys(progress)
    _.map(step_ids, (step) ->
      cfg = wizard_cfg[step]
      unless cfg
        throw new Error "Missing cfg for #{step}"
      {
        id: step
        status: progress[step]
        state: cfg.state
        name: cfg.name
        position: cfg.position
        class: cfg.class
      }
    )

  currentStep = (steps) ->
    _.find(steps, (step) ->
      if Array.isArray(step.state)
        _.any(step.state, (s) -> s == $state.current.name)
      else
        step.state == $state.current.name
    )

  nextStep = (steps) ->
    current = currentStep(steps)
    if current
      _.find(steps, (step) ->
        step.position == (current.position + 1)
      )
    else
      _(steps)
        .sortBy((s) -> s.position)
        .first()

  isDisabled = (steps, step) ->
    prior = _.select(steps, (s) -> s.position < step.position)
    _.any(prior, (s) -> s.status == 'invalid') && step.status != 'valid'

  buildSteps: buildSteps
  currentStep: currentStep
  nextStep: nextStep
  isDisabled: isDisabled


export default stepHelper

