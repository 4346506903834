export class History<T = any> {
  values: T[] = []
  currentIndex = -1
  current: T
  frozen = false

  freeze() {
    this.frozen = true
  }
  unfreeze() {
    this.frozen = false
  }
  redo() {
    if (this.frozen) return this.current
    if (this.currentIndex < this.values.length - 1) {
      this.currentIndex++
      this.current = this.values[this.currentIndex]
    }
    return this.current
  }
  new(value) {
    if (this.frozen) return this.current
    this.values.splice(this.currentIndex + 1, this.values.length)
    this.values.push(value)
    this.currentIndex++
    this.current = this.values[this.currentIndex]

    return this.current
  }
  undo() {
    if (this.frozen) return this.current
    if (this.currentIndex > 0) {
      this.currentIndex--
      this.current = this.values[this.currentIndex]
    }
    return this.current
  }
  set(value: any) {
    if (this.frozen) return this.current
    this.values[this.currentIndex] = value
    this.current = this.values[this.currentIndex]
  }
}
