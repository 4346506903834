const appStateSvc = function () {
  "ngInject";

  let is_sidebar_toggled = false;
  return {
    isSidebarToggled() {
      return is_sidebar_toggled;
    },
    setSidebarToggled(val) {
      return (is_sidebar_toggled = val);
    },
    toggleSidebar() {
      console.log(is_sidebar_toggled);
      return (is_sidebar_toggled = !is_sidebar_toggled);
    },
  };
};

export default appStateSvc;
