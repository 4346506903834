htmlToPlainText = (
) ->
  "ngInject"

  (value) ->
    return '' unless value
    cleanText = value.replace(/<\/?[^>]+(>|$)/g, "")
    cleanText = cleanText.replace(/&nbsp;/g, " ")
    return cleanText

export default htmlToPlainText

