const CalcRequestSvc = function (Restangular, $stateParams) {
  "ngInject";

  const svc = {
    //This will fetch a completed_calc out of redis
    one(id) {
      //The id is the redis cache key
      return Restangular.one("calcs", id);
    },
    //This will queue a calc job in rabbit to be worked async
    queue_calcs() {
      return Restangular.all("calcs");
    },
  };

  return svc;
};

export default CalcRequestSvc;
