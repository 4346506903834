const ExperienceSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("experiences");
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {};
      const obj = jQuery.extend(true, new_attrs, attrs);
      const rest_obj = Restangular.restangularizeElement(null, obj, "experiences", {});
      rest_obj.fromServer = false;
      return rest_obj;
    },
  };

  return svc;
};

export default ExperienceSvc;
