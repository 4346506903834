import * as _ from "lodash";

const participantStep = function (ngToast, ParticipantSvc, $uibModal) {
  "ngInject";

  const sensory_test = null;
  const event = null;
  const participant_group = null;
  const participant_groups = [];
  const participants = [];
  const panels = [];
  const import_count = null;
  const is_first_group = true;
  const full_messages = [];
  const refreshed_at = Date.now();
  const history_state = null;
  const import_state = null;
  const import_params = null;

  const initialize = function (init_data) {
    this.event = init_data.event;
    this.panels = init_data.panels;
    this.participant_groups = init_data.participant_groups;
    this.sensory_test = init_data.sensory_test;
    // used to inform the user their design will be rebuilt if they up the n
    _.each(this.participant_groups, function (pg) {
      pg.original_n = pg.needed_participant_count;
    });
    // for create_first_group.html
    this.participant_group = _(this.participant_groups)
      .select(pg => {
        return pg.event_id === this.event.id;
      })
      .first();
    this.history_state = init_data.history_state;
    this.import_state = init_data.import_state;
    this.import_params = init_data.import_params;
    if (this.participant_group && this.participant_group.fromServer) {
      return (this.is_first_group = false);
    } else {
      this.is_first_group = true;
      if (!this.participant_group) {
        this.participant_group = this.participant_groups.add({
          starting_participant_code: 1000 * (this.participant_groups.length + 1) + 1,
          event_id: this.event.id,
          padding_ratio: init_data.currentUser.padding_ratio,
        });
        return this.participant_groups.sort((a, b) => (a.id > b.id ? 1 : -1));
      }
    }
  };

  const reset = function () {
    this.import_count = null;
    this.is_first_group = true;
    this.sensory_test = null;
    return (this.event = null);
  };

  const max_code = pg => pg.starting_participant_code + pg.needed_participant_count * pg.padding_ratio - 1;

  const title = function (pg) {
    return `${pg.starting_participant_code} - ${max_code(pg)}`;
  };

  const hideStartingCode = function () {
    return (
      this.event.checkin_type === "self_checkin" ||
      (this.sensory_test.test_type === "descriptive" && this.event.checkin_type !== "no_checkin") ||
      this.event.location_type === "online"
    );
  };

  const hideCreateGroup = function () {
    return (
      this.event.checkin_type === "self_checkin" ||
      (this.sensory_test.test_type === "descriptive" && this.event.checkin_type !== "no_checkin") ||
      this.event.location_type === "online"
    );
  };

  const isValid = function () {
    this.full_messages = [];
    let is_valid = true;
    const pg_count = _(this.participant_groups)
      .select(pg => pg.fromServer)
      .value().length;
    if (pg_count < 1 && !this.is_first_group) {
      is_valid = false;
      this.full_messages.push("Please create a participant group");
    }
    // if (@sensory_test.test_type is 'descriptive' and @event.checkin_type isnt 'no_checkin')
    // if !@event.panel_id && @event.location_type is 'on_site'
    //   is_valid = false
    //   @full_messages.push 'Please select a panel.'
    _.each(this.participant_groups, pg => {
      pg.full_messages = [];
      if (!pg.needed_participant_count) {
        is_valid = false;
        pg.errors = { needed_participant_count: "required" };
        pg.full_messages.push("Number of participants is required.");
      }
      if (!pg.starting_participant_code) {
        is_valid = false;
        pg.errors = { starting_participant_code: "required" };
        pg.full_messages.push("Starting participant code is required.");
      }
    });
    return is_valid;
  };

  const panelAvailable = function () {
    return (
      this.event &&
      this.event.location_type === "on_site" &&
      ["self_checkin", "checkin"].includes(this.event.checkin_type)
    );
  };

  const canAddParticipants = function () {
    if (!this.event) {
      return false;
    }

    if (this.event.checkin_type === "checkin") {
      if (this.event.location_type === "on_site") {
        return true;
      } else if (this.event.location_type === "off_site") {
        return true;
      } else if (
        this.event.location_type === "online" &&
        ["email_participants", "packing_slip"].includes(this.event.online_type)
      ) {
        return true;
      }
    } else if (
      this.event.checkin_type === "no_checkin" &&
      this.event.location_type === "online" &&
      this.event.online_type === "email_participants"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const canImportParticipants = function () {
    if (this.event == null) {
      return false;
    }
    if (
      _.any(this.participant_groups) &&
      (this.event.checkin_type === "checkin" ||
        this.event.location_type === "online" ||
        (this.event.checkin_type === "no_checkin" && this.event.is_launched))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const addParticipantGroup = function (disableEventSelect) {
    if (disableEventSelect == null) {
      disableEventSelect = false;
    }
    return $uibModal
      .open({
        templateUrl: "ui/participant_groups/create.html",
        controller: "participantGroupCreateCtrl",
        resolve: {
          block_design_id() {
            return null;
          },
          sensory_test: () => this.sensory_test,
          participant_group() {
            return null;
          },
          disableEventSelect() {
            return disableEventSelect;
          },
        },
      })
      .result.then(
        result => {
          if (result.ok) {
            this.participant_groups.add(result.participant_group);
            this.participant_groups.sort((a, b) => (a.id > b.id ? 1 : -1));
            return ngToast.success({ content: "Successfully created participant group." });
          }
        },
        function (result) {
          if (!result) {
            return;
          }
          return _.each(result.full_messages, msg =>
            ngToast.danger({ content: "Successfully created participant group." }),
          );
        },
      );
  };

  const showOnlineEmails = function () {
    if (this.event == null) {
      return false;
    }
    return this.event.online_type === "email_participants";
  };

  const showParticipantForm = function () {
    if (this.event == null) {
      return false;
    }
    return !this.event.panel_id; // && @event.checkin_type != 'no_checkin'
  };

  return {
    sensory_test,
    event,
    participant_group,
    participant_groups,
    participants,
    title,
    import_count,
    is_first_group,
    full_messages,
    history_state,
    import_state,
    import_params,

    initialize,
    hideStartingCode,
    hideCreateGroup,
    isValid,
    canAddParticipants,
    addParticipantGroup,
    canImportParticipants,
    showOnlineEmails,
    panelAvailable,
    showParticipantForm,
    reset,
  };
};

export default participantStep;
