import * as angular from "angular"

const LocationSvc = function (Restangular, moment, currentUser) {
  "ngInject"

  Restangular.extendModel("locations", function (obj) {
    if (obj && typeof obj === "object") {
      return angular.extend(obj, {
        timezone_name() {
          return moment.tz(this.timezone_id).format("zz (Z)")
        },
      })
    }
    return obj
  })
  const svc = {
    all() {
      return Restangular.all("locations")
    },
    one(id) {
      return Restangular.one("locations", id)
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {}
      }
      const new_attrs = {
        name: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        rooms: [],
        timezone_id: "",
        email: "",
        phone_country_code: "",
        phone_area_code: "",
        phone_number: "",
        location_priorities: [],
        language: "en_US",
        exposure: "partner",
        event_selection: "disallow_reservation_conflict",
        user_ids: [currentUser.id],
      }

      const obj = jQuery.extend(true, new_attrs, attrs)
      const rest_obj = Restangular.restangularizeElement(null, obj, "locations", {})
      rest_obj.fromServer = false
      return rest_obj
    },
  }

  return svc
}

export default LocationSvc
