// https://raw.githubusercontent.com/jutaz/angular-trumbowyg/master/src/components/trumbowyg/trumbowyg.js

// require("trumbowyg");
import "./trumbowyg.js";
import "./trumbowyg-table.js";
import "trumbowyg/dist/plugins/colors/trumbowyg.colors.js";

const trumbowyg = {
  templateUrl: "directives/angular-trumbowyg.html",
  bindings: {
    editorConfig: "<?",
    ngDisabled: "<?",
    placeholder: "@?",
    onFocus: "&?",
    onBlur: "&?",
    onInit: "&?",
    onChange: "&?",
    onResize: "&?",
    onPaste: "&?",
    onOpenfullscreen: "&?",
    onClosefullscreen: "&?",
    onClose: "&?",
  },
  require: {
    ngModel: "ngModel",
  },
  controller: [
    "$element",
    "$scope",
    "$attrs",
    function ($element, $scope, $attrs) {
      const TBW_EVENTS = [
          "focus",
          "blur",
          "init",
          "change",
          "resize",
          "paste",
          "openfullscreen",
          "closefullscreen",
          "close",
        ],
        EVENTS_PREFIX = "tbw";

      this.getElementReference = () => angular.element($element.find("> div"));

      this.getEditorReference = () => this.getElementReference().find(".trumbowyg-editor");

      this.updateModelValue = () => {
        $scope.$applyAsync(() => {
          const value = this.getEditorReference().trumbowyg("html");
          this.ngModel.$setViewValue(value);
        });
      };

      this.emitEvent = event => {
        const attr = $attrs.$normalize(`on-${event}`);
        if (angular.isFunction(this[attr])) {
          $scope.$applyAsync(() => this[attr]());
        }
      };

      this.initializeEditor = (element, options) => {
        element
          .trumbowyg(options)
          .on("tbwchange", () => this.updateModelValue())
          .on("tbwpaste", () => this.updateModelValue());
        angular.forEach(TBW_EVENTS, event => {
          element.on(`${EVENTS_PREFIX}${event}`, () => this.emitEvent(event));
        });
        this.ngModel.$render();
      };

      this.$onDestroy = () => {
        this.getElementReference().trumbowyg("destroy");
      };

      this.$onChanges = changes => {
        const element = this.getElementReference();
        if (changes.editorConfig && !changes.editorConfig.isFirstChange()) {
          element.trumbowyg("destroy");
        }

        if (changes.editorConfig) {
          this.initializeEditor(
            element,
            angular.extend(
              {
                svgPath: "/angular/icons.svg",
                svgSideLoad: false,
                autogrow: true,
              },
              this.editorConfig,
            ),
          );
        }

        if (changes.ngDisabled) {
          element.trumbowyg(this.ngDisabled ? "disable" : "enable");
        }

        if (changes.placeholder) {
          this.getEditorReference().attr("placeholder", this.placeholder);
        }
      };

      this.$onInit = () => {
        this.ngModel.$render = () => {
          const element = this.getEditorReference();
          var value = null;
          if (this.ngModel.$modelValue !== undefined && this.ngModel.$modelValue == null) {
            // this allows us to push null state into trumbowyg
            // https://github.com/Alex-D/Trumbowyg/blob/v2.21.0/src/trumbowyg.js#L1102
            value = "";
          } else {
            value = this.ngModel.$modelValue;
          }

          element.trumbowyg("html", value);
        };
      };
    },
  ],
};

export default trumbowyg;
