import * as _ from "lodash";

const validationHelper = function ($rootScope, $uibModal, $state) {
  "ngInject";

  return {
    unsavedDataCheck(form) {
      return $rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams) {
        if (form.$dirty) {
          event.preventDefault();
          return $uibModal
            .open({
              templateUrl: "rj_shared/dialog.html",
              controller: "dialogCtrl",
              resolve: {
                settings() {
                  return {
                    title: "Unsaved Changes",
                    message: "Your changes have not been saved",
                    style: "danger",
                    ok_button: "Continue without saving",
                    cancel_button: "cancel",
                  };
                },
              },
            })
            .result.then(function (result) {
              if (result) {
                form.$setPristine();
                return $state.go(toState);
              }
            });
        }
      });
    },

    addErrorsToForm(form, errors) {
      form.find(".error-error-error").html("");
      form.find(".has-error").removeClass("has-error");

      return _.each(errors, function (values, key) {
        let fg;
        let input_control = $('[name="' + key + '"]');
        if (!(input_control.length > 0)) {
          input_control = $('[ng-model="' + key + '"]');
        }
        if (input_control.length > 0) {
          fg = input_control.closest(".form-group");
        } else {
          fg = $('[error-key="' + key + '"]');
        }

        fg.addClass("has-error");
        const message_container = fg.find(".error-error-error");

        if (message_container) {
          return _.each(values, value => message_container.append(`<p>${value}</p>`));
        }
      });
    },

    addErrorsToSummary(form, full_messages, errors) {
      const summary = form.find(".error_summary");
      if (summary) {
        summary.html("");
        summary.append("<div class='alert alert-danger'></div>");
        _.each(full_messages, function (value) {
          summary.find(".alert").append(`<p>${value}</p>`);
        });
      }

      if (errors) {
        return _.each(errors, function (values, key) {
          let fg;
          let input_control = $('[name="' + key + '"]');
          if (!(input_control.length > 0)) {
            input_control = $('[ng-model="' + key + '"]');
          }
          if (input_control.length > 0) {
            fg = input_control.closest(".form-group");
          } else {
            fg = $('[error-key="' + key + '"]');
          }

          return fg.addClass("has-error");
        });
      }
    },

    clear(form) {
      form.find(".error-error-error").html("");
      return form.find(".has-error").removeClass("has-error");
    },

    firstError(response) {
      try {
        const errors = response.data.messages;
        const first_key = Object.keys(errors)[0];
        const err = errors[first_key];
        if ($.isArray(err)) {
          return _.first(err);
        } else {
          return err;
        }
      } catch (error) {
        return "An error has occurred.";
      }
    },
  };
};

export default validationHelper;
