googlePlace = (
  uiGmapGoogleMapApi
) ->
  "ngInject"

  require: "ngModel"
  link: (scope, element, attrs, model) ->
    options =
#     types: ['street_address', 'route']
      types: ['geocode']
      componentRestrictions: {}
    uiGmapGoogleMapApi.then (maps) ->
      scope.gPlace = new google.maps.places.Autocomplete(element[0], options)
      google.maps.event.addListener scope.gPlace, "place_changed", ->
        scope.$apply ->
          model.$setViewValue element.val()
          return

      return

    return

export default googlePlace
