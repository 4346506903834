const RecipientNotificationSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("recipient_notifications");
    },
    one(id) {
      return Restangular.one("recipient_notifications", id);
    },
  };
  return svc;
};

export default RecipientNotificationSvc;
