import * as _ from "lodash"
import * as angular from "angular"

const SubjectSvc = function (Restangular) {
  "ngInject"

  Restangular.extendModel("subjects", function (obj) {
    if (obj && typeof obj === "object") {
      obj.archive = obj.remove
      obj.redact = () => obj.customDELETE("redact")
      obj.restore = () => obj.customPUT({ archived_at: null })
    }
    return obj
  })

  this.base_url = "subjects"

  const svc = {
    all() {
      return Restangular.all("subjects")
    },
    me() {
      return Restangular.all("subjects").customGET("me")
    },
    one(id) {
      return Restangular.one("subjects", id)
    },
    search(options) {
      return Restangular.all("subjects").customPOST(options, "search")
    },
    panel_search() {
      return Restangular.allUrl("subjects/panelist_search")
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {}
      }
      const new_attrs = {
        name: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        email: "",
        code: "",
        head_of_household: true,
      }
      const obj = jQuery.extend(true, new_attrs, attrs)
      const rest_obj = Restangular.restangularizeElement(null, obj, "subjects", {})
      rest_obj.fromServer = false
      return rest_obj
    },
  }

  _.each(svc, (v, k) => {
    if (!_.includes(["all", "one", "new"], k)) {
      return Restangular.extendModel(`${this.base_url}/${k}`, obj => {
        return angular.extend(obj, { route: this.base_url })
      })
    }
  })

  return svc
}

export default SubjectSvc
