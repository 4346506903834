import * as _ from "lodash"

currencySymbol = (
  CURRENCIES
) ->
  "ngInject"

  (key) ->
    currency = _.find(CURRENCIES, (c) -> c.key == key)
    return '$' if !currency || currency.symbol == ''
    currency.symbol
export default currencySymbol

