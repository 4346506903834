frequencyDistributionSettings = (
  $window
) ->
  "ngInject"

  restrict: "AE"
  scope: {
    reporting: '=',
    config: '=',
    labelText: '@',
    dirtyFlag: '=',
    saveCallback: '&'
  }
  templateUrl: "rj_shared/frequency_distribution_settings.html"

  link: ($scope, element, attrs) ->
    unless $scope.labelText
      $scope.labelText = "Frequency Distribution"

    $scope.addBreakpoint = () ->
      if !$scope.reporting.frequency_distribution_breakpoints
        $scope.reporting.frequency_distribution_breakpoints = []

      fdps = $scope.reporting.frequency_distribution_breakpoints

      newValue = 0
      totalRange = $scope.config.max - $scope.config.min

      if fdps.length == 0
        defaultStep = (totalRange/5).toFixed(0)
        newValue = $scope.config.max - defaultStep
      else if fdps.length == 1
        bp = fdps[0]
        step = $scope.config.max - bp.value
        newValue = bp.value - step
        if newValue < $scope.config.min
          newValue = $scope.config.min
      else
        nextToLastBP = fdps[fdps.length-2]
        bottomBP = fdps[fdps.length-1]
        step = nextToLastBP.value - bottomBP.value
        newValue = bottomBP.value - step

      new_bp = {id: guid(), value: newValue.toFixed(0).toString()}
      fdps.push(new_bp)
      fdps = _.sortBy($scope.reporting.frequency_distribution_breakpoints, (fdb) -> parseFloat(fdb.value)).reverse()

      $scope.focused_id = new_bp.id
      $scope.updateSource()

    $scope.removeBreakpoint = (breakPoint) ->
      $scope.reporting.frequency_distribution_breakpoints = _.reject($scope.reporting.frequency_distribution_breakpoints, (bp) -> bp.id == breakPoint.id)
      $scope.updateSource()

    $scope.clearBreakpoints = () ->
      $scope.reporting.frequency_distribution_breakpoints = []
      $scope.updateSource()

    $scope.updateSource = () ->
      if ($scope.saveCallback)
        $scope.saveCallback()

      if $scope.dirtyFlag?
        $scope.dirtyFlag = true

    $scope.isFocused = (id) ->
      id == $scope.focused_id

    return

export default frequencyDistributionSettings
