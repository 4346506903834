import * as _ from "lodash"
import cookies from "../utils/cookies"

const geocoder = function ($http, $q) {
  "ngInject"

  const _get = function (google_address, type) {
    const thing = _.filter(google_address.address_components, item => _.includes(item.types, type))
    let item = _.first(thing)
    if (!item) {
      item = {
        long_name: "",
        short_name: "",
      }
    }
    return item
  }

  const parseAddress = function (addr) {
    const location = {
      full_name: addr.formatted_address,
      address: addr.formatted_address.split(",")[0],
      city: _get(addr, "locality").long_name,
      state: _get(addr, "administrative_area_level_1").short_name,
      zip: _get(addr, "postal_code").long_name,
      country: _get(addr, "country").short_name,
      latitude: addr.geometry.location.lat,
      longitude: addr.geometry.location.lng,
    }

    return location
  }

  const geocode = function (address) {
    const deferred = $q.defer()
    $http({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookies.getCookie("auth"),
      },
      url: "/api/v1/maps/geocode?address=" + address,
    }).then(function (result) {
      const { data } = result
      if (data.response.length > 0) {
        return deferred.resolve(parseAddress(data.response[0]))
      } else {
        return deferred.resolve(null)
      }
    })
    return deferred.promise
  }

  return { geocode }
}

export default geocoder
