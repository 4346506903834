import * as angular from "angular"
import 'angular-moment'
import datetimepicker from 'angularjs-bootstrap-datetimepicker'
import angularbootstrap from 'angular-ui-bootstrap'

require("./consent.sass")
import 'angularjs-bootstrap-datetimepicker/src/css/datetimepicker.css'


constants = angular.module('redjade.consent', [
  "angularMoment"
  'ui.bootstrap.datetimepicker'
  angularbootstrap
])
  .controller('consentCtrl', require("./consent.controller").default)
  .controller('consentFailCtrl', require("./consentFail.controller").default)
  .run(($templateCache) ->
    "ngInject"
    # HTML
    $templateCache.put('ui/subject_consent/agreement_languages.html', require("./agreement_languages.html"))
    $templateCache.put('ui/subject_consent/consent.html', require("./consent.html"))
    $templateCache.put('ui/subject_consent/consent_agreement_card.html', require("./consent_agreement_card.html"))
    $templateCache.put('ui/subject_consent/consent_contact_card.html', require("./consent_contact_card.html"))
    $templateCache.put('ui/subject_consent/consent_declined.html', require("./consent_declined.html"))
  )
  .name
export default constants
