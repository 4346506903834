percentFormatter = (
  $filter
) ->
  "ngInject"

  return (
    p = (viewValue) ->
      m = viewValue.match(/^(\d+)\/(\d+)/)
      # if m != null
      #   return $filter('number')(parseInt(m[1]) / parseInt(m[2]), 2)
      $filter('number') parseFloat(viewValue) / 100

    f = (modelValue) ->
      $filter('number') parseFloat(modelValue) * 100

    {
      require: 'ngModel'
      link: (scope, ele, attr, ctrl) ->
        ctrl.$parsers.unshift p
        ctrl.$formatters.unshift f
        return
    }

  )

export default percentFormatter
