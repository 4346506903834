heightSync = (
  $timeout
  $rootScope
  heightSyncService
) ->
  "ngInject"

  return (
    scope:
      syncGroupId: "@heightSync"

    link: ($scope, element, attributes) ->

      #change height on a new wrapper div so the child is free to change with its contents
      $(element[0]).wrap("<div class='heightSync'></div>")

      #watch the element for size changes and broadcast to the heightSyncService
      new ResizeObserver(() ->
        $rootScope.$broadcast('heightSync', {syncGroupId: $scope.syncGroupId})
      ).observe(element[0])

      return
  )

export default heightSync
