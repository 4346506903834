const RoomSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("rooms");
    },
    one(id) {
      return Restangular.one("rooms", id);
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const default_attrs = {};
      const obj = jQuery.extend(true, default_attrs, attrs);
      return Restangular.restangularizeElement(null, obj, "rooms");
    },
  };

  return svc;
};

export default RoomSvc;
