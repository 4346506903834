redjadeOnly = (
  currentUser
) ->
  "ngInject"

  return (
    link: (scope, element) ->
      unless currentUser.red_jade_admin
        element.remove()
      return
  )


export default redjadeOnly

