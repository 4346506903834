sidebar = (
  $parse
  $log
) ->
  "ngInject"

  {
    require: '^sidebarset'
    restrict: 'EA'
    replace: true
    transclude: true
    scope:
      active: '=?'
      onSelect: '&select'
      onDeselect: '&deselect'

    controller: ->
      #Empty controller so other directives can require being 'under' a tab
      return
    link: (scope, elm, attrs, sidebarsetCtrl, transclude) ->
      scope.$watch 'active', (active) ->
        if active
          sidebarsetCtrl.select scope
        return
      scope.disabled = false
      # if attrs.disable
      #   scope.$parent.$watch $parse(attrs.disable), (value) ->
      #     scope.disabled = ! !value
      #     return
      # Deprecation support of "disabled" parameter
      # fix(tab): IE9 disabled attr renders grey text on enabled tab #2677
      # This code is duplicated from the lines above to make it easy to remove once
      # the feature has been completely deprecated
      # if attrs.disabled
      #   $log.warn 'Use of "disabled" attribute has been deprecated, please use "disable"'
      #   scope.$parent.$watch $parse(attrs.disabled), (value) ->
      #     scope.disabled = ! !value
      #     return

      scope.select = ->
        if !scope.disabled
          scope.active = true
        return

      sidebarsetCtrl.addSidebar scope
      scope.$on '$destroy', ->
        sidebarsetCtrl.removeSidebar scope
        return
      #We need to transclude later, once the content container is ready.
      #when this link happens, we're inside a tab heading.
      scope.$transcludeFn = transclude
      return

  }


export default sidebar

