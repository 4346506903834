const OffsitePartnershipSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("offsite_partnerships");
    },

    one(id) {
      return Restangular.one("offsite_partnerships", id);
    },
  };

  return svc;
};

export default OffsitePartnershipSvc;
