const UserSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("users");
    },
    one(id) {
      return Restangular.one("users", id);
    },
    meta(id, event_id) {
      return this.one(id).customGET("meta");
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = { email: "" };
      // TODO: set access_levels based on the current user
      const obj = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, obj, "users");
    },
  };
  return svc;
};

export default UserSvc;
