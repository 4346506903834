const VariableSvc = function (Restangular, $stateParams) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("variables");
    },
  };

  return svc;
};

export default VariableSvc;
