const commonFunctions = function () {
  "ngInject";

  return {
    filterChoices(term, choice_defaults, selected_value) {
      let choice, value;
      const data: any = {};
      data.results = [];
      let found = false;

      if (term) {
        for (choice of Array.from(choice_defaults)) {
          value = choice.value.toLocaleLowerCase();
          term = term.toLocaleLowerCase();

          if (value.localeCompare(term) === 0) {
            found = true;
            break;
          }
        }
      }

      if (!found) {
        data.results.push({ id: term, value: term.toString() });
      }

      for (choice of Array.from(choice_defaults)) {
        ({ value } = choice);
        term = term;

        if (value.indexOf(term) >= 0) {
          data.results.push(choice);
        }
      }

      if (selected_value) {
        if (selected_value.value) {
          data.results.push(selected_value);
        } else {
          const v = { id: selected_value, value: selected_value.toString() };
          data.results.push(v);
        }
      }
      return data.results;
    },
  };
};

export default commonFunctions;
