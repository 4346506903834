import * as _ from "lodash"
import * as angular from "angular"

variableReplacer = (
  variableCache
  $compile
) ->
  "ngInject"


  escape = (s) ->
    s.replace /[-/\\^$*+?.()|[\]{}]/g, '\\$&'

  # DEPRECATED: ios 12
  handleIOS12Image = (value) ->
    ua = navigator.userAgent
    # return value if (!(/(iPhone|iPad|iPod)/.test(ua) && /OS 12_\d/.test(ua)))

    pngImgs = []
    imgs = value.match(/<img[^>]+>/g)
    if imgs
      for img in imgs
        idx = img.indexOf('?')
        if idx > -1
          pngImgs.push(img.slice(0, idx) + "?format=png&" + img.slice(idx + 1))
    else
      pngImgs.push(value.slice(0, value.indexOf('?')) + "?format=png&" + value.slice(value.indexOf('?') + 1))

    return pngImgs.join('')


  replace = (value, variables, element, scope) ->

    if value
      value = value.replace(/(?:\r\n|\r|\n)/g, '<br />'); #replace new lines with breaks
      angular.forEach value.match(var_pattern), (matched_var) ->
        idx = value.indexOf(matched_var)
        if idx > -1
          value_for_replace = variables?[matched_var.replace(/#|{{|}}/g, "")]
          if value_for_replace?# and value_for_replace?

            # DEPRECATED: ios 12
            if matched_var.includes('sample_image')
              value_for_replace = handleIOS12Image(value_for_replace)

            value = value.replace(new RegExp(escape(matched_var), "g"), value_for_replace)
        return

    else
      value = ""
    element.html value
    element.html
    _.each(element.find(".builder-variable"), (el) ->  $compile(el)(scope.$new()))
    # $compile(value_for_replace)(scope.$new())
    return
    #     (#\w+#|{{\w+}})
    #    (?:{{|#)sample_(\w+)(?:}}|#)
  var_pattern = /((?:{{|#)\w+(?:\[\w+\])*(?:}}|#))/g # replace any word characters wrapped in {{this}} #or_this#
  restrict: "A"
  replace: true
  scope:
    text: "=variableReplacer"
    local_variables: "=variables"
    sample_id: "=sampleId"

  link: compile = (scope, element, attrs, controller) ->

    scope.variables = scope.local_variables || variableCache.data

    scope.$watch "local_variables", (value) ->
      scope.variables = value if value
      replace scope.text, scope.variables, element, scope
      return

    scope.$watch "text", (value) ->
      replace scope.text, scope.variables, element, scope
      return


    return

export default variableReplacer

