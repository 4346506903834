import { textFromArrayOfObjects, setNestedProperty, cloneObject } from "../utils/list"
import { History } from "./History"
export class ListEditor {
  // assigned in constructor
  isDisabled: boolean
  arrayModel: any[]
  pathToTextProperty: string
  refreshDOMFunc: Function
  onActiveLinesChange: Function
  textarea: HTMLTextAreaElement
  uuid?: string
  arrayModelAddItem?: Function
  beforeDelete?: Function

  // assigned before constructor
  pathToProperty = <string[]>null
  linesArray = <string[]>[]
  ignoreWatch = false
  selectionStart = 0
  selectionStartLine = -1
  selectionEnd = 0
  selectionEndLine = -1
  activeLines = <any>{}
  newlineIndices = <number[]>[]
  settingLines = false
  typing = false
  typingTimeout = <any>null
  textCopy = "" // @ts-ignore
  updating = false
  shiftKey = false
  initialValue = ""
  modelHistory = new History()
  cursorHistory = new History()

  builderHelper?: any

  constructor(config: {
    isDisabled: boolean
    arrayModel: any[]
    arrayModelAddItem?: Function
    pathToTextProperty: string
    refreshDOMFunc: Function
    onActiveLinesChange: Function
    textarea: HTMLTextAreaElement
    uuid?: string
    beforeDelete?: Function
    builderHelper?: any
  }) {
    for (let key in config) {
      this[key] = config[key]
    }
    this.setTextArea(this.textarea)
  }

  setPathToTextProperty(path: string) {
    let oldPath = this.pathToTextProperty
    this.pathToTextProperty = path
    this.afterPathSet(oldPath)
  }

  afterPathSet(oldPath = this.pathToTextProperty) {
    if (!this.pathToProperty || this.pathToTextProperty != oldPath) {
      this.pathToProperty = this.pathToTextProperty.split(".")
    }
  }

  updateTextValues = (newArr: any[], oldArr: any[]) => {
    this.afterPathSet()
    this.textarea.value = textFromArrayOfObjects(this.arrayModel, this.pathToProperty)

    // if the textarea is empty, and none active, default to first line, this was not happening by default
    if (this.textarea.value.trim() == "" && Object.keys(this.activeLines).length == 0) {
      this.textarea.selectionStart = 0
      this.textarea.selectionEnd = 0
    }

    if (!this.textarea.value) {
      this.textarea.value = this.arrayModel
        .slice(0, -1)
        .map(() => "\n")
        .join("")
    }
    this.linesArray = this.textarea.value.split("\n")
    this.ignoreWatch = false
    this.afterUpdate(false)
  }

  guardDelete = async (func?: Function) => {
    if (!func) {
      return false
    }
    return await func()
  }

  updateDOM = () => {
    this.refreshDOMFunc()
  }

  // Breaks new text from the textarea into the this.linesArray, and then updates the model array
  updateModelValues = () => {
    const newSplit = this.textarea.value.split("\n")
    this.ignoreWatch = true
    this.linesArray.splice(0, Infinity)
    this.linesArray = newSplit
    if (this.linesArray.length > this.arrayModel.length) {
      for (let i = this.arrayModel.length; i < this.linesArray.length; i++) {
        this.addSpaceToArrayModel()
      }
    }
    if (this.linesArray.length < this.arrayModel.length) {
      const diff = this.arrayModel.length - this.linesArray.length
      const start = this.textarea.selectionStart
      const end = this.textarea.selectionEnd
      let text: any = this.textarea.value.split("")
      text.splice(this.textarea.selectionEnd, 0, ...new Array(diff).fill("\n"))
      this.linesArray = text.join("").split("\n")
      this.textarea.value = this.linesArray.join("\n")
      setTimeout(() => {
        this.textarea.selectionStart = start
        this.textarea.selectionEnd = end
      }, 0)
    }
    this.assignValuesToModel()
    this.afterUpdate(true)
  }

  afterUpdate = async (forceDigest = true) => {
    this.setNewlineIndices()
    if (this.textCopy != this.textarea.value) {
      const numOldLines = this.textCopy.split("\n").length
      this.textCopy = this.textarea.value
      this.storeHistory()
      if (this.checkDeletedLines(numOldLines)) {
        const confirm = await this.guardDelete(this.beforeDelete)
        if (!confirm) {
          setTimeout(() => {
            this.jumpHistory("undo")
          }, 0)
        }
      }
    }
    if (forceDigest) {
      this.updateDOM()
    }
    if (!this.updating) {
      this.updating = true
      setTimeout(() => {
        this.resumeChanges()
      }, 0)
    }
  }

  resumeChanges = () => {
    this.ignoreWatch = false
    this.updating = false
    this.modelHistory.unfreeze()
  }

  checkDeletedLines = (numOldLines: number) => {
    return numOldLines > this.linesArray.length
  }

  setNewlineIndices = () => {
    const text = this.textarea.value
    this.newlineIndices = []
    for (let i = 0; i < text.length; i++) {
      if (text[i] == "\n") {
        this.newlineIndices.push(i)
      }
    }
  }

  addSpaceToArrayModel = () => {
    if (this.arrayModelAddItem) {
      this.arrayModelAddItem()
    } else {
      this.arrayModel.push("")
    }
  }

  // updates 2-way bound array passed in with new values based on the property key
  assignValuesToModel = (expand = false) => {
    const path = this.pathToTextProperty || this.pathToProperty
    for (let i = 0; i < this.linesArray.length; i++) {
      const value = this.linesArray[i]
      if (expand && i >= this.arrayModel.length) this.addSpaceToArrayModel()
      setNestedProperty(this.arrayModel[i], path, value)
    }
  }

  storeHistory() {
    this.modelHistory.new(cloneObject(this.arrayModel))
    this.cursorHistory.new([this.textarea.selectionStart, this.textarea.selectionEnd])
  }

  undo() {
    if (this.modelHistory.currentIndex > 0 && !this.modelHistory.frozen) {
      this.jumpHistory("undo")
    }
  }

  redo() {
    if (!this.modelHistory.frozen) {
      this.jumpHistory("redo")
    }
  }

  jumpHistory(action: "undo" | "redo") {
    this.arrayModel.splice(0, this.arrayModel.length, ...this.modelHistory[action]())
    const data = this.cursorHistory[action]()
    this.modelHistory.freeze()
    this.cursorHistory.freeze()
    this.updateDOM()
    setTimeout(() => {
      this.afterHistoryUpdate(data)
    }, 0)
  }

  afterHistoryUpdate(selectionStartEnd: number[]) {
    this.textarea.selectionStart = selectionStartEnd[0]
    this.textarea.selectionEnd = selectionStartEnd[1]
    this.modelHistory.unfreeze()
    this.cursorHistory.unfreeze()
    this.setActiveLines()
  }

  setCursorToLine(lineNumber: number) {
    let index = 0
    if (lineNumber > 0) {
      index = this.linesArray.slice(0, lineNumber).reduce((a, b) => a + b).length
    }

    if (this.textarea) {
      let num = index + (lineNumber - 1)
      this.textarea.selectionStart = num
      this.textarea.selectionEnd = num
      this.selectionEnd = num
      this.selectionStart = num
      this.setActiveLines()
      return index
    }

    this.setActiveLines()
    return null
  }

  setCurrentSelectionLocations() {
    const textarea = <HTMLTextAreaElement>document.getElementById(this.uuid)
    const start = textarea.selectionStart
    const end = textarea.selectionEnd
    this.selectionStart = start
    this.selectionEnd = end
  }

  setActiveLines(cancelEmit = false) {
    if (this.settingLines) return

    this.settingLines = true
    const textarea = <HTMLTextAreaElement>document.getElementById(this.uuid)
    const indices = [-1, ...this.newlineIndices, textarea.value.length]

    this.setCurrentSelectionLocations()

    const start = this.selectionStart
    const end = this.selectionEnd
    const lower = end < start ? end : start
    const higher = end < start ? start : end

    for (let i = 0; i < indices.length; i++) {
      const prev = indices[i - 1]
      const next = indices[i]
      if (lower > prev && lower <= next) {
        this.activeLines[i] = true
        this.selectionStartLine = prev
        this.selectionEndLine = prev
      } else if (higher > prev && lower <= prev) {
        this.activeLines[i] = true
        this.selectionEndLine = prev
      } else {
        delete this.activeLines[i]
      }
    }
    if (!cancelEmit) {
      this.onActiveLinesChange(this.activeLines, this.uuid)
    }
    this.cursorHistory.set([start, end])
    this.settingLines = false
  }

  unsetActiveLines() {
    this.activeLines = {}
    this.selectionEndLine = -1
    this.selectionStartLine = -1
    clearTimeout(this.typingTimeout)
    this.onActiveLinesChange(this.activeLines, this.uuid)
  }

  setModelValue(i: number, value: any) {
    const path = this.pathToTextProperty || this.pathToProperty
    setNestedProperty(this.arrayModel[i], path, value)
  }

  insertValuesIntoModel(values: any[], insertionPoint: number) {
    if (values.length > 0) {
      // first, append to end
      for (let i = 0; i < values.length; i++) {
        this.addSpaceToArrayModel()
        this.setModelValue(this.arrayModel.length - 1, values[i])
      }
      const newValues = this.arrayModel.splice(-1 * values.length, values.length)
      // then, move into the insertion point
      for (let i = 0; i < newValues.length; i++) {
        this.arrayModel.splice(insertionPoint, 0, newValues[newValues.length - 1 - i])
      }
    }
  }

  handleEmptyPaste(textValues: string[], firstLine: number, lines: number[]) {
    // handle paste into empty space
    let emptySpliceEnd = 0
    let emptySpaceStart = firstLine
    let inc = 0

    if (lines.length == 1 && this.linesArray[firstLine] && this.linesArray[firstLine + 1] == "") {
      inc = 1
    }
    // update the values of the empty slots
    for (let i = 0; i < textValues.length; i++) {
      let j = emptySpaceStart + i
      if (!this.linesArray[j + inc] && j + inc < this.linesArray.length) {
        this.setModelValue(j + inc, textValues[i])
        firstLine++
        emptySpliceEnd++
      } else {
        break
      }
    }
    // handling conditional pasting above/below line while first filling the new white spaces
    textValues.splice(0, emptySpliceEnd)
    if (inc) {
      lines[0]++
    } else {
      lines.splice(0, emptySpliceEnd)
    }
  }

  async deleteLinesOnBackspace() {
    let start =
      this.textarea.selectionStart > this.textarea.selectionEnd
        ? this.textarea.selectionEnd
        : this.textarea.selectionStart
    const filtered = this.arrayModel.filter((item, i) => !this.activeLines[i + 1])
    this.arrayModel.splice(0, this.arrayModel.length, ...filtered)
    this.textarea.value = textFromArrayOfObjects(this.arrayModel, this.pathToProperty)
    this.textarea.selectionStart = start
    this.textarea.selectionEnd = start
    this.linesArray = this.textarea.value.split("\n")
    this.afterUpdate()
    setTimeout(() => {
      this.setActiveLines()
    }, 0)
    this.clearTypingTimeout()
  }

  deleteTextAndKeepLines(useVm?: boolean) {
    let newText = ""
    let start = useVm ? this.selectionStart : this.textarea.selectionStart
    let end = useVm ? this.selectionEnd : this.textarea.selectionEnd
    for (let i = 0; i < this.textarea.value.length; i++) {
      if (i >= start && i < end) {
        if (this.textarea.value[i] == "\n") {
          newText += this.textarea.value[i]
        }
      } else {
        newText += this.textarea.value[i]
      }
    }
    this.textarea.value = newText
    this.textarea.selectionStart = start
    this.textarea.selectionEnd = start
    this.typing = false
    this.ignoreWatch = true
    this.clearTypingTimeout()
    this.updateModelValues()
    setTimeout(() => this.setActiveLines(), 0)
  }

  wait = (time: number) =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve("The Promise has resolved")
      }, time)
    })

  async clickHandler(e: MouseEvent) {
    const textarea = e.target as HTMLTextAreaElement
    if (e.detail >= 3) {
      if (textarea.value[textarea.selectionEnd - 1] == "\n") {
        textarea.selectionEnd--
      }
      this.setActiveLines()
      e.preventDefault()
      return false
    }
    if (e.detail == 2) {
      if (textarea.value[textarea.selectionEnd - 1] == "\n") {
        while (true) {
          const char = textarea.value[textarea.selectionStart - 1]
          if (!char || char.match(/\s/)) {
            break
          }
          textarea.selectionStart--
        }
        textarea.selectionEnd--
      }
      this.setActiveLines()
      e.preventDefault()
      return false
    }
    if (textarea.selectionStart == textarea.selectionEnd) {
      this.setActiveLines()
    } else {
      setTimeout(() => this.setActiveLines(), 1)
    }
  }

  // everything can just be an array of characters with newlines being a "character"
  // then positions is position in the array
  basicCharacterArray(textArray) {
    // insert in between every element a newline character
    let withNewlines = textArray.reduce((acc, val, i) => {
      if (i !== textArray.length - 1) {
        return [...acc, val, "\n"]
      } else {
        return [...acc, val]
      }
    }, [])

    return withNewlines.map(val => (val !== "\n" ? val.split("") : val)).flat()
  }

  verifyInsertLocation(charArrayWithNewlines, insertCharArray, newlineRegex) {
    const beginningOfLine =
      this.selectionStart == 0 || newlineRegex.test(charArrayWithNewlines[this.selectionStart - 1])
    const endOfLine =
      this.selectionStart == charArrayWithNewlines.length ||
      newlineRegex.test(charArrayWithNewlines[this.selectionStart])

    // act as normal if
    //    - no newline chars in what we are pasting
    //    - anything has been selected
    //    - line is blank
    if (
      !newlineRegex.test(insertCharArray) ||
      this.selectionStart != this.selectionEnd ||
      (beginningOfLine && endOfLine)
    )
      return true

    // if cursor at beginning of line and pasting text has newline chars in it, assume user wants to prepend list with new entries
    // otherwise assume user wants to insert new lines after current line
    let pasteDirectionDown = false
    if (!beginningOfLine) {
      pasteDirectionDown = true
      for (let i = this.selectionStart; i <= charArrayWithNewlines.length; i++) {
        this.selectionEnd = this.selectionStart = i + 1
        if (newlineRegex.test(charArrayWithNewlines[i])) break
      }
    }

    // we use tabs because we always split on these
    charArrayWithNewlines.splice(this.selectionStart, 0, "\t")

    return pasteDirectionDown
  }

  spliceWithRules(charArrayWithNewlines, insertCharArray, regex, startingLineCount) {
    // replace blank lines if enough new lines are pasted
    let replaced = 0
    if (regex.test(charArrayWithNewlines[this.selectionEnd])) {
      const remaining = charArrayWithNewlines
        .slice(this.selectionEnd + 1)
        .join("")
        .split(regex)
      let canReplaceCount = 0
      for (const r of remaining) {
        if (r.trim() !== "" && !regex.test(r)) break

        canReplaceCount++
      }
      const offset =
        this.selectionStart < this.selectionEnd &&
        (this.selectionEnd == charArrayWithNewlines.length - 1 || regex.test(charArrayWithNewlines[this.selectionEnd]))
          ? 1
          : 0
      const newLinesCount = insertCharArray.join("").split(regex).length - offset
      replaced = newLinesCount > canReplaceCount ? canReplaceCount : newLinesCount
    }

    charArrayWithNewlines.splice(
      this.selectionStart,
      this.selectionEnd - this.selectionStart + replaced,
      insertCharArray,
    )

    // if we are pasting less than we had selected, pad those with new lines
    const pastedLineCount = charArrayWithNewlines.flat().join("").split(regex).length
    for (let i = 0; i < startingLineCount - pastedLineCount; i++)
      charArrayWithNewlines.splice(this.selectionStart + 1, 0, "\t")

    return charArrayWithNewlines
      .flat()
      .join("")
      .split(regex)
      .map(val => val.trim())
  }

  otherPathsBlank() {
    // no applicable if not dealing with locales
    if (!this.builderHelper || !this.builderHelper.locales.find(l => this.pathToProperty.includes(l))) return false

    let locale = this.pathToProperty[this.pathToProperty.length - 1]
    let pathNoLocale = this.pathToProperty.slice(0, -1)
    let blank = true
    for (let otherLocale of this.builderHelper.locales.filter(l => l != locale)) {
      let text = textFromArrayOfObjects(this.arrayModel, [...pathNoLocale, otherLocale]).trim()
      if (text != "") {
        blank = false
        break
      }
    }
    return blank
  }

  pasteHandler(e: any) {
    e.preventDefault()

    this.ignoreWatch = true
    this.clearTypingTimeout()
    let pastedText = e.clipboardData.getData("text").replace(/\r/g, "").trim()
    this.setCurrentSelectionLocations() // home/end can cause selection to be inaccurate

    let regex = /\n|\t/
    // only convert newlines to spaces if has tabs because that's a horizontal row
    if (this.shiftKey && pastedText.includes("\t")) pastedText = pastedText.replace(/\n/g, " ")
    const textValues: string[] = pastedText.split(regex)

    let charArrayWithNewlines = this.basicCharacterArray(this.linesArray)
    let insertCharArray = this.basicCharacterArray(textValues)
    const startingLineCount = charArrayWithNewlines.join("").split(regex).length
    const pasteDirectionDown = this.verifyInsertLocation(charArrayWithNewlines, insertCharArray, regex)
    let updatedTextValues = this.spliceWithRules(charArrayWithNewlines, insertCharArray, regex, startingLineCount)
    this.ignoreWatch = false

    if (
      !this.builderHelper ||
      this.builderHelper.locales.length <= 1 ||
      updatedTextValues.length <= this.linesArray.length ||
      this.otherPathsBlank()
    ) {
      let newLocation = this.selectionStart + insertCharArray.length
      // there is a race condition in one of these handlers where without this delay something overrides to last position
      setTimeout(() => {
        this.textarea.selectionStart = this.textarea.selectionEnd = newLocation
      }, 10)
      return this.basicUpdate(updatedTextValues, pasteDirectionDown)
    }

    this.builderHelper.BuilderModals.editPastedTextModal(updatedTextValues).then(updated => {
      if (updated == false) return

      this.basicUpdate(updated.split(regex), pasteDirectionDown)
    })
  }

  // new lines in builder need to be new response entries so existing line numbers dont change
  // simplest example is using shift+enter in middle of list
  syncLinesArrayWithNew(lines, pasteDirectionDown) {
    if (!this.arrayModelAddItem) return

    let activeLineIndex = Math.min(
      ...Object.keys(this.activeLines)
        .filter(key => this.activeLines[key] === true)
        .map(Number),
    )

    // since we fill in existing lines we need to increment the activeLineIndex down below the last existing empty line being filled in
    while (
      pasteDirectionDown &&
      activeLineIndex < this.linesArray.length &&
      this.linesArray[activeLineIndex].trim() == ""
    )
      activeLineIndex++

    const blanks = new Array(lines.length - this.linesArray.length).fill("")
    let insertIncrement = pasteDirectionDown ? 0 : -1
    if (blanks.length) {
      // i dont know what this bug is but it cant push to the start of the list cleanly but one at a time works anywhere else in the list
      // so im doing the same thing two ways
      if (!pasteDirectionDown && (activeLineIndex == 1 || activeLineIndex == this.linesArray.length))
        this.insertValuesIntoModel(blanks, activeLineIndex + insertIncrement)
      else
        for (let _blank of blanks) {
          this.insertValuesIntoModel([""], activeLineIndex + insertIncrement)
          activeLineIndex++
        }
    }
  }

  basicUpdate(lines, pasteDirectionDown) {
    this.ignoreWatch = true
    this.syncLinesArrayWithNew(lines, pasteDirectionDown)
    while (lines.length < this.linesArray.length) lines.push("")
    this.linesArray = lines
    this.assignValuesToModel()
    this.afterUpdate(true)
    this.setActiveLines()
    this.ignoreWatch = false
  }

  setTypingTimeout() {
    this.typingTimeout = setTimeout(() => {
      this.updateModelValues()
      this.typing = false
    }, 300)
  }

  clearTypingTimeout() {
    clearTimeout(this.typingTimeout)
  }

  keyupHandler(e) {
    this.shiftKey = e.shiftKey
  }

  async keydownHandler(e: KeyboardEvent) {
    const textarea = e.target as HTMLTextAreaElement
    this.shiftKey = e.shiftKey
    if (e.key == "ArrowDown" || e.key == "ArrowUp" || e.key == "ArrowLeft" || e.key == "ArrowRight") {
      if (this.textCopy != this.textarea.value) {
        this.updateModelValues()
      }
      setTimeout(() => this.setActiveLines(), 5)
    }
    // Jump to next line, or start a new line
    if (e.key === "Enter") {
      textarea.selectionStart = textarea.selectionEnd
      const snippet = textarea.value.slice(textarea.selectionStart, textarea.value.length)
      const indexOfNextLine = snippet.indexOf("\n")
      if (e.shiftKey) {
        const curLine = parseInt(Object.keys(this.activeLines)[0])
        this.insertValuesIntoModel([""], curLine)
        this.updateTextValues(this.arrayModel, this.arrayModel)
        this.setCursorToLine(curLine + 1)
        e.preventDefault()
        return false
      } else if (indexOfNextLine > -1) {
        textarea.selectionStart += indexOfNextLine + 1
      } else {
        textarea.value += "\n"
        textarea.selectionStart = textarea.value.length + 1
        textarea.selectionEnd = textarea.value.length + 1
      }
      this.updateModelValues()
      setTimeout(() => this.setActiveLines(), 5)
      e.preventDefault()
      return false
    }
    // delete batch lines if shift key is held
    else if (e.key == "Delete" || e.key == "Backspace") {
      this.setActiveLines()
      this.wait(0)
      if (e.shiftKey) {
        this.deleteLinesOnBackspace()
        e.preventDefault()
        return false
        // prevent jumping to previous line from backspacing too much
      } else if (this.selectionStart == this.selectionEnd) {
        const cursorOffset = this.selectionStart - this.selectionStartLine - 1
        if (cursorOffset == 0) {
          e.preventDefault()
          return false
        }
      } else {
        this.deleteTextAndKeepLines()
        e.preventDefault()
        return false
      }
    }
    if (e.metaKey || e.ctrlKey) {
      // Redo windows/linux
      if (e.key === "y") {
        this.redo()
        e.preventDefault()
        return false
      } else if (e.key === "z") {
        // Redo mac
        if (e.shiftKey) {
          this.redo()
        }
        // Undo windows/linux/mac
        else {
          this.undo()
        }
        e.preventDefault()
        return false
      } else if (e.key === "a") {
        setTimeout(() => this.setActiveLines(), 5)
      }
      return true
    }
  }

  typingAction() {
    this.clearTypingTimeout()
    this.setTypingTimeout()
    if (!this.typing) {
      this.updateModelValues()
      this.setActiveLines()
      this.typing = true
      this.ignoreWatch = true
    }
  }

  copyModelHistoryInPlace(array: any[]) {
    this.modelHistory.undo()
    this.modelHistory.new(cloneObject(array))
  }

  arrayChanged(newArr: any[], oldArr: any[]) {
    this.copyModelHistoryInPlace(oldArr)
    if (this.ignoreWatch) {
      return
    }
    this.updateTextValues(newArr, oldArr)
  }

  textPropertyChanged(path?: string) {
    if (path) this.setPathToTextProperty(path)
    if (this.ignoreWatch) return
    this.updateTextValues(this.arrayModel, this.arrayModel)
  }

  setTextArea(textarea: HTMLTextAreaElement) {
    if (!textarea.getAttribute("data-select-listening")) {
      textarea.setAttribute("data-select-listening", "true")
      textarea.onselect = () => this.setActiveLines()
      textarea.onkeydown = (e: any) => this.keydownHandler(e)
      textarea.onkeyup = (e: any) => this.keyupHandler(e)
      textarea.onpaste = (e: any) => this.pasteHandler(e)
      textarea.onblur = () => this.setActiveLines()
      textarea.onmouseleave = () => this.setActiveLines()
      textarea.onclick = (e: any) => this.clickHandler(e)
      textarea.oninput = (e: any) => this.typingAction()
    }
  }
}
