const PanelPerformanceSettingsSvc = function (Restangular, $stateParams) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("panel_performance_settings");
    },
    one(id) {
      return Restangular.one("panel_performance_settings", id);
    },
  };

  return svc;
};

export default PanelPerformanceSettingsSvc;
