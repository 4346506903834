const AnswerDemographicMappingSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all(sensory_test_id) {
      if (sensory_test_id) {
        return Restangular.one("sensory_tests", sensory_test_id).all("answer_demographic_mappings");
      } else {
        return Restangular.all("answer_demographic_mappings");
      }
    },
  };
  return svc;
};

export default AnswerDemographicMappingSvc;
