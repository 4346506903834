const RecruitingBlastReportSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("recruiting_blast_reports");
    },
    one(id) {
      return Restangular.one("recruiting_blast_reports", id);
    },
  };

  return svc;
};

export default RecruitingBlastReportSvc;
