const SampleSvc = function (Restangular) {
  "ngInject";

  const svc = {
    blinding_codes() {
      return Restangular.all("blinding_codes");
    },
    all() {
      return Restangular.all("samples");
    },
    one(id) {
      return Restangular.one("samples", id);
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      if (!$.isNumeric(attrs.project_id)) {
        throw "project_id is required";
      }

      return Restangular.one("samples")
        .customGET("build", attrs)
        .then(function (response) {
          const new_attrs = response;
          const sample = jQuery.extend(true, new_attrs, attrs);
          return Restangular.restangularizeElement(null, sample, "samples");
        });
    },
  };

  return svc;
};

export default SampleSvc;
