const ConsentAgreementVersionSvc = function (Restangular, $stateParams) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("consent_agreement_versions");
    },
    latest() {
      return Restangular.all("consent_agreement_versions").customGET("latest");
    },
    one(id) {
      return Restangular.one("consent_agreement_versions", id);
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {
        publish_action: "no_action",
        contact: {},
        description: {},
      };
      const obj = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, obj, "consent_agreement_versions");
    },
  };

  return svc;
};

export default ConsentAgreementVersionSvc;
