import * as _ from "lodash"

const OnboardingConfigurationSvc = function (Restangular) {
  "ngInject"

  Restangular.extendModel("onboarding_configurations", function (obj) {
    if (obj && typeof obj === "object") {
      obj.archive = obj.remove
      obj.restore = () =>
        obj.customPUT({ archived_at: null, demographic_category_ids: _.map(obj.demographic_categories, dc => dc.id) })
      return obj
    }
  })

  const svc = {
    all() {
      return Restangular.all("onboarding_configurations")
    },
    one(id) {
      return Restangular.one("onboarding_configurations", id)
    },
    new(currentUser, attrs) {
      if (attrs == null) {
        attrs = {}
      }
      const new_attrs = {
        company_id: currentUser.company_id,
        exposure: "invite_only",
        required: true,
        locales: ["en_US"],
      }
      const ob = jQuery.extend(true, new_attrs, attrs)
      return Restangular.restangularizeElement(null, ob, "onboarding_configurations", null)
    },
  }

  return svc
}

export default OnboardingConfigurationSvc
