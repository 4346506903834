const ScreenerSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("screeners");
    },
    one(id) {
      return Restangular.one("screeners", id);
    },
  };

  return svc;
};

export default ScreenerSvc;
