percentScaler = (
) ->
  "ngInject"

  {
    restrict: 'EA'
    transclude: true
    scope:
      breaks: '='
      direction: '='
      percent: '='
      numerator: '='
      denominator: '='
      decimals: '='
      config: '='
    templateUrl: '/partials/recruiting_blasts/percent_scaler.html'
    controller: 'percentScalerCtrl'
  }
export default percentScaler

