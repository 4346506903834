selectOnClick = (
  $window
) ->
  "ngInject"

  {
    restrict: 'A'
    link: (scope, element, attrs) ->
      element.on 'click', ->
        if !$window.getSelection().toString()
          # Required for mobile Safari
          @setSelectionRange 0, @value.length
        return
      return

  }


export default selectOnClick

