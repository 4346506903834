topNav = (
) ->
  "ngInject"

  return {
    restrict: 'E',
    replace: true,
    controller: 'topNavCtrl',
    templateUrl: "rj_shared/top_nav.html"
  }

export default topNav

