import * as _ from "lodash";

const sensoryTestSearchSvc = function ($uibModal, $q, $rootScope, LocationSvc, UserSvc) {
  "ngInject";

  const data: any = {
    total: null,
    filters: [],
    filter_info: {},
  };

  const getSearchData = params => ({
    include_archived: data.filter_info.include_archived,
    location_ids: data.filter_info.location_ids,
    search_term: data.filter_info.search_term,
    active_before: data.filter_info.active_before,
    active_after: data.filter_info.active_after,
    created_by_id: data.filter_info.created_by_id,
    status_greater_than_or_equal_to: data.filter_info.status_greater_than_or_equal_to,
    sensory_test_id: data.sensory_test_id,
  });

  // "reset" function
  const initializeFilterInfo = function (params) {
    data.total = null;
    const search_params = params;
    const intArray = function (key) {
      let ids = search_params[key];
      if (ids && !_.isArray(ids)) {
        ids = [ids];
      }
      return _.map(ids, d => parseInt(d));
    };
    // we extend here to allow for pre-initialization route based initial configuration for different apps use-cases
    data.filter_info = _.extend(
      {
        demographic_ids: intArray("demographic_ids"),
        location_ids: intArray("location_ids"),
        order_by: search_params.order_by || "created_at",
        order_dir: search_params.order_dir || "desc",
        page: search_params.page || 1,
        per: search_params.per || 10,
        search_term: search_params.search_term || "",
        include_archived: search_params.include_archived,
        active_before: search_params.active_before,
        active_after: search_params.active_after,
        created_by_id: parseInt(search_params.created_by_id),
        status_greater_than_or_equal_to: search_params.status_greater_than_or_equal_to,
        sensory_test_id: search_params.sensory_test_id,
      },
      data.filter_info,
    );

    buildFilters();
  };

  const toggleArchived = function () {
    data.filter_info.include_archived = !data.filter_info.include_archived;
    return changed();
  };

  const addFilter = () =>
    $uibModal
      .open({
        templateUrl: "rj_shared/sensory_tests/filter_modal.html",
        controller: "sensoryTestFilterModalCtrl",
        size: "lg", // accomodate all the tabs within tabs within tabs
        resolve: {
          locations() {
            return data.locations;
          },
          creator() {
            return data.creator;
          },
          filter_info() {
            return data.filter_info;
          },
        },
      })
      .result.then(
        function (response) {
          data.filter_info.location_ids = response.location_ids;
          data.filter_info.active_before = response.active_before ? moment(response.active_before).toISOString() : null;
          data.filter_info.active_after = response.active_after ? moment(response.active_after).toISOString() : null;
          data.filter_info.created_by_id = response.created_by_id;
          data.filter_info.status_greater_than_or_equal_to = response.status_greater_than_or_equal_to;
          return changed();
        },
        function () {
          // cancelled
        },
      );

  var buildFilters = function () {
    const deferred = $q.defer();
    const makem = function (locations, users) {
      let filters = [];
      if (_.any(data.filter_info.location_ids) && _.any(locations)) {
        filters = _.union(
          filters,
          _.map(data.filter_info.location_ids, function (location_id) {
            const location = _.find(locations, d => d.id === location_id);
            return {
              type: "location",
              id: location.id,
              name: location.name,
              meta: location.address,
            };
          }),
        );
      }
      if (data.filter_info.active_before) {
        filters.push({
          type: "active_before",
          id: null,
          name: ` ${moment(data.filter_info.active_before).format("MM/DD/YY")}`,
          meta: null,
        });
      }
      if (data.filter_info.active_after) {
        filters.push({
          type: "active_after",
          id: null,
          name: `${moment(data.filter_info.active_after).format("MM/DD/YY")}`,
          meta: null,
        });
      }
      if (data.filter_info.created_by_id) {
        filters.push({
          type: "creator",
          id: null,
          name: data.creator != null ? data.creator.full_name : undefined,
          meta: null,
        });
      }
      if (data.filter_info.status_greater_than_or_equal_to) {
        filters.push({
          type: "status",
          id: null,
          name: data.filter_info.status_greater_than_or_equal_to,
          meta: null,
        });
      }
      return filters;
    };

    const promises = [];
    promises.push(LocationSvc.all().getList({ per: "all" }));
    if (data.filter_info.created_by_id) {
      promises.push(UserSvc.all().customGET("search", { id: data.filter_info.created_by_id }));
    }
    $q.all(promises).then(function (responses) {
      data.locations = responses[0];
      data.creator = _.find(responses[1], u => u.id === data.filter_info.created_by_id);
      data.filters = makem(data.locations, data.users);
      return deferred.resolve(data.filters);
    });
    return deferred.promise;
  };

  const removeFilter = function (filter) {
    switch (filter.type) {
      case "location":
        data.filter_info.location_ids = _.without(data.filter_info.location_ids, filter.id);
        break;
      case "active_before":
        data.filter_info.active_before = null;
        break;
      case "active_after":
        data.filter_info.active_after = null;
        break;
      case "creator":
        data.filter_info.created_by_id = null;
        break;
      case "status":
        data.filter_info.status_greater_than_or_equal_to = null;
        break;
    }
    data.filters = _.without(data.filters, filter);
    return changed();
  };

  const clearAllFilters = function () {
    _.each(data.filters, function (f) {
      removeFilter(f);
    });
  };

  const setActive = function (sensory_test) {
    data.sensory_test_id = sensory_test.id;
    $rootScope.$broadcast("sensory_test_activated", { sensory_test_id: sensory_test.id });
    return changed();
  };

  var changed = (options = null) => $rootScope.$broadcast("LIST_SEARCH_CHANGED", options);

  return {
    pluralize: {
      one: "1 sensory test",
      other: "{} sensory tests",
    },
    data,
    getSearchData,
    initializeFilterInfo,
    toggleArchived,
    buildFilters,
    addFilter,
    removeFilter,
    clearAllFilters,
    setActive,
    changed,
  };
};

export default sensoryTestSearchSvc;
