const TagSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("tags");
    },
    one(id) {
      return Restangular.one("tags", id);
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {
        name: "",
        color: "",
        bg_color: "",
      };
      const data = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, data, "tags", false);
    },
  };

  return svc;
};

export default TagSvc;
