import jspdf from "jspdf";
import * as html2canvas from "html2canvas";
import * as html2pdf from "html2pdf.js";

const pdfSvc = function () {
  "ngInject";

  function generate(pdfName: string): void {
    const HTML_WIDTH = $(".canvas__div-pdf").width();
    const HTML_HEIGHT = $(".canvas__div-pdf").height();
    const TOP_LEFT_MARGIN = 15;
    const PDF_WIDTH = HTML_WIDTH + TOP_LEFT_MARGIN * 2;
    const PDF_HEIGHT = PDF_WIDTH * 1.5 + TOP_LEFT_MARGIN * 2;

    let canvas_image_width = HTML_WIDTH;
    let canvas_image_height = HTML_HEIGHT;
    let totalPDFPages = Math.ceil(HTML_HEIGHT / PDF_HEIGHT) - 1;

    // @ts-ignore
    html2canvas($(".print-preview")[0], {
      allowTaint: false,
      scale: 2,
      dpi: 300,
    }).then(canvas => {
      canvas.getContext("2d");

      let imgData = canvas.toDataURL("image/jpeg", 1.0);

      // let pdf = new jsPDF('p', 'pt', [PDF_WIDTH, PDF_HEIGHT]);
      let pdf = new jspdf("p", "px", "letter");
      let width = pdf.internal.pageSize.getWidth();
      let height = pdf.internal.pageSize.getHeight();
      // pdf.addImage(imgData, 'JPEG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
      pdf.addImage(imgData, "JPEG", 0, 15, width, height);

      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_WIDTH, PDF_HEIGHT], "portrait");
        pdf.addImage(
          imgData,
          "JPEG",
          TOP_LEFT_MARGIN,
          -(PDF_HEIGHT * i) + TOP_LEFT_MARGIN * 4,
          canvas_image_width,
          canvas_image_height,
        );
      }

      pdf.save(pdfName);
    });
  }

  function downloadPdf(event, pdfName: string, orientation: string) {
    $(".no-print").hide();
    const svgElements = document.body.getElementsByTagName("svg");
    Array.from(svgElements).forEach(svgElement => {
      const bBox: any = svgElement.getBBox();
      svgElement.setAttribute("width", bBox.width);
      svgElement.setAttribute("height", bBox.height);
    });

    setTimeout(() => {
      //this time is required so the above style changes can take effect
      let content = document.getElementsByClassName("print-preview")[0];
      const options = {
        margin: [5, 1, 1, 1],
        filename: pdfName,
        pagebreak: { after: [".analysis__card"], before: [".card-chart"] },
        image: { type: "jpeg", quality: 0.7 },
        html2canvas: { scale: 2, dpi: 32, allowTaint: false },
        jsPDF: { unit: "mm", format: "tabloid", orientation: orientation },
      };

      html2pdf()
        .set(options)
        .from(content)
        .save()
        .then(() => {
          $(".no-print").show();
        });
    }, 0);
  }

  return {
    generate,
    downloadPdf,
  };
};

export default pdfSvc;
