const HtmlToPdfSvc = function (Restangular, $stateParams) {
  "ngInject";

  const svc = {
    getPDF(id, params) {
      if (params == null) {
        params = {};
      }
      return Restangular.one("html_to_pdf", id).customPOST(params, "get_pdf");
    },
  };

  return svc;
};

export default HtmlToPdfSvc;
