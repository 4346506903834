import * as _ from "lodash";

const messageHelper = function () {
  "ngInject";

  const firstMessage = function (messages) {
    try {
      const first_key = Object.keys(messages)[0];
      const err = messages[first_key];
      if (Array.isArray(err)) {
        return _.first(err);
      } else {
        return err;
      }
    } catch (error) {
      return "An error has occurred.";
    }
  };

  const hasKeys = function (messages, keys) {
    if (messages) {
      return _.any(Object.keys(messages), k => _.any(keys, key => k === key));
    } else {
      return false;
    }
  };

  const withFullMessages = messages =>
    _.reduce(
      messages,
      function (memo, partial_messages, key) {
        if (!Array.isArray(partial_messages)) {
          partial_messages = [partial_messages];
        }
        if (key === "base") {
          memo[key] = partial_messages;
        } else {
          //my_property: ['msg1', 'msg2'] -> ['My property msg1', 'My property msg2']
          const humanized_key = key.humanize().toLowerCase();
          const title = humanized_key.charAt(0).toUpperCase() + humanized_key.slice(1);
          memo[key] = _.map(partial_messages, msg => `${title} ${msg}`);
        }
        return memo;
      },
      {},
    );

  const onlyMessages = function (messages) {
    let result;
    return (result = _.flatten(_.map(messages, (msgs, key) => msgs)));
  };

  return {
    firstMessage,
    hasKeys,
    withFullMessages,
    onlyMessages,
  };
};

export default messageHelper;
