const StationActivationCodeSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all(query) {
      return Restangular.all("surveys/station_activation_codes");
    },
    one(id) {
      return Restangular.one("surveys/station_activation_codes", id);
    },
  };

  return svc;
};

export default StationActivationCodeSvc;
