const ProductSvc = function (Restangular) {
  "ngInject";

  let svc;
  return (svc = {
    all() {
      return Restangular.all("products");
    },
    one(id) {
      return Restangular.one("products", id);
    },
  });
};

export default ProductSvc;
