import * as _ from "lodash"

const SensoryTestSvc = function (Restangular) {
  "ngInject"

  Restangular.extendModel("sensory_tests", function (test) {
    if (test && typeof test === "object") {
      if (test.participants) {
        Restangular.restangularizeCollection(null, test.participants, "participants")
        _.map(test.participants, participant => (participant.fromServer = true))
      }

      test.update_event = function (data) {
        if (test.events) {
          const evt = _.find(test.events, evt => evt.id === data.id)
          const index = _.indexOf(test.events, evt)
          test.events.splice(index, 1, data)
        }
      }
    }
    return test
  })
  const svc = {
    all() {
      return Restangular.all("sensory_tests")
    },
    one(id) {
      return Restangular.one("sensory_tests", id)
    },
    meta(id, event_id) {
      let str = "meta"
      if (event_id) {
        str = str + `?event_id=${event_id}`
      }
      return this.one(id).customGET(str)
    },
    testMetaForEvent(event_id) {
      return this.all.customGET("meta", { event_id })
    },
    summary(id) {
      return this.one(id).customGET("summary")
    },
    getTypes() {
      return Restangular.one("references", "sensory_test_types").get()
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {}
      }
      const new_attrs = {
        project_id: null,
        test_type: null,
      }
      const obj = jQuery.extend(true, new_attrs, attrs)
      return Restangular.restangularizeElement(null, obj, "sensory_tests")
    },
  }

  return svc
}

export default SensoryTestSvc
