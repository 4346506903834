const RecruitingBlastSvc = function (Restangular, RecruitingCampaignSvc) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("recruiting_blasts");
    },
    one(id) {
      return Restangular.one("recruiting_blasts", id);
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {
        retry_attempts: 0,
        retry_frequency: 0,
        recruiting_blast_schedulings: [
          {
            subject_count: 0,
            send_at: null,
          },
        ],
      };
      const blast = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, blast, "recruiting_blasts");
    },
  };
  return svc;
};

export default RecruitingBlastSvc;
