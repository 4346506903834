humanizeConstant = (
) ->
  "ngInject"

  (text) ->
    if text
      string = text.split('_').join(' ').toLowerCase()
      string = string.charAt(0).toUpperCase() + string.slice(1)
      return string
    return

export default humanizeConstant

