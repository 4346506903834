focusMe = (
  $timeout
) ->
  "ngInject"

  return (
    scope:
      trigger: "=focusMe"
      focusedIndex: "="
      currentIndex: "="

    link: ($scope, element) ->
      doFocus = ->
        $timeout(->
          $(element[0]).select()
          element[0].focus()
          if $scope.trigger is true
            $scope.trigger = false
          return
        , 10)
        return

      if $scope.trigger is true || !$scope.trigger?
        doFocus()

      $scope.$watch "trigger", (value, old_value) ->
        if value is true
          doFocus()
        return

      $scope.$watch "focusedIndex", (value) ->
        if value && value == $scope.currentIndex
          $timeout(->
            $(element[0]).select()
            element[0].focus()
            $scope.focusedIndex = null
            return
          , 10)
        return

      return
  )

export default focusMe
