import * as _ from "lodash"
import * as angular from "angular"

sidebarSetCtrl = (
  $scope
  appStateSvc
  $state
  $previousState
) ->
  "ngInject"

  ctrl = this
  sidebars = ctrl.sidebars = $scope.sidebars = []
  $scope.active_index = 0
  $scope.appStateSvc = appStateSvc
  $scope.previousState = $previousState
  $scope.options = {}

  ctrl.select = (selectedSidebar) ->
    angular.forEach sidebars, (sidebar) ->
      if sidebar.active and sidebar != selectedSidebar
        sidebar.active = false
        sidebar.onDeselect()
        selectedSidebar.selectCalled = false
      return
    selectedSidebar.active = true
    $scope.active_index = _.indexOf(sidebars, selectedSidebar)
    # only call select if it has not already been called
    if !selectedSidebar.selectCalled
      selectedSidebar.onSelect()
      selectedSidebar.selectCalled = true
    return

  ctrl.addSidebar = (sidebar) ->
    sidebars.push sidebar
    if sidebar.active || sidebar.active == null || sidebar.active == undefined
      angular.forEach sidebars, (sidebar) ->
        sidebar.active = false
      sidebar.active = true
      $scope.active_index = sidebars.length - 1

  ctrl.removeSidebar = (sidebar) ->
    index = sidebars.indexOf(sidebar)
    #Select a new tab if the tab to be removed is selected and not destroyed
    if sidebar.active and sidebars.length > 1 and !destroyed
      #If this is the last tab, select the previous tab. else, the next tab.
      $scope.active_index = if index == sidebars.length - 1 then index - 1 else index + 1
      ctrl.select sidebars[$scope.active_index]
    sidebars.splice index, 1
    return

  ctrl.setOptions = (options) ->
    $scope.options = options

  $scope.home = ->
    if($scope.options.homeSref)
      $state.go($scope.options.homeSref)
      return

    currentSidebar = sidebars[0]
    if currentSidebar
      $scope.active_index = 0
      angular.forEach sidebars, (sidebar, index) ->
        if sidebar.active and sidebar != currentSidebar
          sidebar.active = false
          sidebar.onDeselect()
          currentSidebar.selectCalled = false
        return
      currentSidebar.active = true
      # only call select if it has not already been called
      if !currentSidebar.selectCalled
        currentSidebar.onSelect()
        currentSidebar.selectCalled = true
      return

  $scope.back = ->
    history.back()

  $scope.previous = () ->
    currentSidebar = sidebars[$scope.active_index - 1] if sidebars[$scope.active_index - 1]
    if currentSidebar
      $scope.active_index = $scope.active_index - 1
      angular.forEach sidebars, (sidebar, index) ->
        if sidebar.active and sidebar != currentSidebar
          sidebar.active = false
          sidebar.onDeselect()
          currentSidebar.selectCalled = false
        return
      currentSidebar.active = true
      # only call select if it has not already been called
      if !currentSidebar.selectCalled
        currentSidebar.onSelect()
        currentSidebar.selectCalled = true
      return

  $scope.next = () ->
    currentSidebar = sidebars[$scope.active_index + 1] if sidebars[$scope.active_index + 1]
    if currentSidebar
      $scope.active_index = $scope.active_index + 1
      angular.forEach sidebars, (sidebar, index) ->
        if sidebar.active and sidebar != currentSidebar
          sidebar.active = false
          sidebar.onDeselect()
          currentSidebar.selectCalled = false
        return
      currentSidebar.active = true
      # only call select if it has not already been called
      if !currentSidebar.selectCalled
        currentSidebar.onSelect()
        currentSidebar.selectCalled = true
      return

  destroyed = undefined
  $scope.$on '$destroy', ->
    destroyed = true
    return
  return

export default sidebarSetCtrl

