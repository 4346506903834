unique = (
) ->
  "ngInject"

  (scope, element, attrs) ->
    element.bind "keydown keypress", (event) ->
      scope.$apply ->
        element.addClass "has-error"
        res = scope.$eval(attrs.unique)
        element.addClass "has-error"  unless res
        return
      return
    return

export default unique

