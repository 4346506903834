stopPropagate = (
) ->
  "ngInject"

  {
    restrict: 'C',
    link: (scope, element, attr) ->
      element.bind('click', (e) ->
        e.stopPropagation()
        return
      )
      return
  }

export default stopPropagate
