calendarView = (
) ->
  "ngInject"

  {
    restrict: 'A'
    link: (scope, element, attrs) ->
      element.on 'click', ->
        $('#calendar').fullCalendar 'changeView', attrs.calendarView
        return
      return

  }

export default calendarView
