const EventSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("events");
    },
    one(id) {
      return Restangular.one("events", id);
    },
    lookup() {
      return Restangular.all("events/lookup");
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {
        sensory_test_id: null,
        location_id: null,
        checkin_type: "no_checkin",
        compensation: 0.0,
        required_session_count: 1,
        using_participant_code_verification: true,
        using_static_url: false,
        online_type: "not_online",
      };
      const event = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, event, "events");
    },
  };

  return svc;
};

export default EventSvc;
