const RecruitingDemographicCategorySvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("recruiting/demographic_categories");
    },
    one(id) {
      return Restangular.one("recruiting/demographic_categories", id);
    },
  };
  return svc;
};

export default RecruitingDemographicCategorySvc;
