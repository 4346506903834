const StationSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("stations");
    },
    one(id) {
      return Restangular.one("stations", id);
    },
    lookup() {
      return Restangular.all("stations/lookup");
    },
    skip(id) {
      return Restangular.one(`stations/${id}/skip`);
    },
    abandon(id) {
      return Restangular.one(`stations/${id}/abandon`);
    },
  };

  return svc;
};

export default StationSvc;
