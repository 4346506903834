import * as _ from "lodash"

const CalcPayloadFilterSvc = function (Restangular) {
  "ngInject"

  Restangular.extendModel("calc_payload_filters", function (filter) {
    if (filter && typeof filter === "object") {
      if (filter.calc_payload_filter_criterias) {
        Restangular.restangularizeCollection(
          null,
          filter.calc_payload_filter_criterias,
          "calc_payload_filter_criterias",
        )
        _.map(filter.calc_payload_filter_criterias, ps => (ps.fromServer = true))
      }
    }
    return filter
  })

  const svc = {
    all() {
      return Restangular.all("calc_payload_filters")
    },
    one(id) {
      return Restangular.one("calc_payload_filters", id)
    },
    new(test_id, attrs) {
      if (attrs == null) {
        attrs = {}
      }
      const new_attrs = {
        name: null,
        id: null,
        criteria_data: [],
        sensory_test_id: test_id,
      }
      const me = jQuery.extend(true, new_attrs, attrs)
      return Restangular.restangularizeElement(null, me, "calc_payload_filters")
    },
  }

  return svc
}

export default CalcPayloadFilterSvc
