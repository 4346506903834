const SessionReminderSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("session_reminders");
    },
    one(id) {
      return Restangular.one("session_reminders", id);
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = { minutes_before: "" };
      const obj = jQuery.extend(true, new_attrs, attrs);
      const rest_obj = Restangular.restangularizeElement(null, obj, "session_reminders", {});
      rest_obj.fromServer = false;
      return rest_obj;
    },
  };
  return svc;
};

export default SessionReminderSvc;
