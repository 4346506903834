consentFailCtrl =
  (
    $scope
    $state
    $stateParams
    consent_state
    exit_state
  ) ->
    "ngInject"

    go_to_consent = ->
      $state.go(consent_state, $stateParams)

    vm = {
      consent_state: consent_state
      exit_state: exit_state
      go_to_consent: go_to_consent
    }
    $scope.vm = vm

export default consentFailCtrl
