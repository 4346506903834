const CompletedQuestionnaireSvc = function (Restangular, $stateParams) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("completed_questionnaires");
    },
    one(id) {
      return Restangular.one("completed_questionnaires", id);
    },
  };

  return svc;
};

export default CompletedQuestionnaireSvc;
