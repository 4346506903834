const RecruitingBlastRecipientSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("recruiting_blast_recipients");
    },

    new(sensory_test, attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {};
      const me = jQuery.extend(true, new_attrs, attrs);
      if (sensory_test) {
        return Restangular.restangularizeElement(sensory_test, me, "recruiting_blast_recipients");
      } else {
        return Restangular.restangularizeElement(null, me, "recruiting_blast_recipients");
      }
    },
  };

  return svc;
};

export default RecruitingBlastRecipientSvc;
