import * as angular from "angular"

redjade_filters = angular.module('redjade.filters', [])
  .filter('currencyName', require("./currencyName.filter").default)
  .filter('currencyName', require("./currencyName.filter").default)
  .filter('currencySymbol', require("./currencySymbol.filter").default)
  .filter('dateString', require("./dateString.filter").default)
  .filter('htmlToPlainText', require("./htmlToPlainText.filter").default)
  .filter('humanizeString', require("./humanizeString.filter").default)
  .filter('kebabcase', require("./kebabcase.filter").default)
  .filter('percentage', require("./percentage.filter").default)
  .filter('preferredDate', require("./preferredDate.filter").default)
  .filter('preferredDateTime', require("./preferredDateTime.filter").default)
  .filter('preferredTime', require("./preferredTime.filter").default)
  .filter('preferredTimeTz', require("./preferredTimeTz.filter").default)
  .filter('propsFilter', require("./props.filter").default)
  .filter('servingSampleCode', require("./servingSampleCode.filter").default)
  .filter('trustUrl', require("./trustUrl.filter").default)
  .name

export default redjade_filters
