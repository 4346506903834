fgLine = (
) ->
  "ngInject"

  {
    restrict: 'AC'
    scope:
      model: '=fgLine'
      toggled: '='
    link: (scope, element) ->
      $(element).addClass 'fg-line'
      fg = $(element).closest('.form-group')
      if fg.find('ui-select').length == 0 && fg.find('[text-angular]').length == 0 && fg.find('.wysiwyg-textarea').length == 0 && fg.find('input[type="datetime-local"]').length == 0
        if $('.fg-line')[0]
          if scope.toggled
            $(element).addClass 'fg-with-value'
          $(element).on 'focus', '.form-control', ->
            $(this).closest('.fg-line').addClass 'fg-toggled'
            return
          $(element).on 'blur', '.form-control', ->
            p = $(this).closest('.form-group')
            i = p.find('.form-control').val()
            $(this).closest('.fg-line').removeClass 'fg-toggled'
            if p.hasClass('fg-float')
              if i.length > 0
                $(this).closest('.fg-line').addClass 'fg-with-value'
              else
                $(this).closest('.fg-line').removeClass 'fg-with-value'

            return

      scope.$watch "model", (new_val, old_val) ->
        if new_val || new_val == 0
          $(element).addClass 'fg-with-value'
        return
      return

  }

export default fgLine
