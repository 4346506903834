rjTransclude = (
) ->
  "ngInject"

  {
    restrict: 'EAC'
    link: ($scope, $element, $attrs, controller, $transclude) ->
      if !$transclude
        throw minErr('rjTransclude')('orphan', 'Illegal use of rjTransclude directive in the template! ' + 'No parent directive that requires a transclusion found. ' + 'Element: {0}', startingTag($element))
      scope_type = $attrs['rjTransclude'] || 'sibling'
      switch scope_type
        when 'sibling'
          $transclude (clone) ->
            $element.empty()
            $element.append clone
            return
        when 'parent'
          $transclude $scope, (clone) ->
            $element.empty()
            $element.append clone
            return
        when 'child'
          iChildScope = $scope.$new()
          $transclude iChildScope, (clone) ->
            $element.empty()
            $element.append clone
            $element.on '$destroy', ->
              iChildScope.$destroy()
              return
            return
      return

  }

export default rjTransclude

