import _ from 'lodash'

consentCtrl = (
  $q
  $scope
  $stateParams
  currentUser
  ConsentAgreementVersionSvc
  SubjectConsentResponseSvc
  $location
  $state
  languageHelper
  $rootScope
  current_subject
  user_is_subject
  failure_state
  recruiting_campaign
  subject_interaction
) ->
  "ngInject"

  data = {
    original_data: null
  }

  promises = [
    ConsentAgreementVersionSvc.all().customGET("latest")
  ]
  $q.all(promises).then (results) ->
    vm.agreement = results[0]
    attrs = {
      consent_agreement_version_id: vm.agreement?.id
      subject_id: current_subject.id
      consented: current_subject.consented
      can_email: current_subject.can_email
      can_call: current_subject.can_call
      contact_exclusion_until_date: current_subject.contact_exclusion_until_date
    }
    data.original_data = attrs
    vm.response = SubjectConsentResponseSvc.new(attrs)

    if vm.agreement && _.includes(Object.keys(vm.agreement.description), current_subject.preferred_language)
      setLocale(current_subject.preferred_language)
    # else if _.includes(Object.keys(vm.agreement.description), currentUser.preferred_language)
    #   setLocale(currentUser.preferred_language)

  setLocale = (locale) ->
    $location.search(_.merge($location.search(), {locale: locale}))
    vm.current_locale = if Array.isArray(locale) then locale[0] else locale

  removeExclusion = (form) ->
    vm.response.contact_exclusion_until_date = null
    form.$setDirty() if form

  cancel = (form) ->
    vm.response = SubjectConsentResponseSvc.new(data.original_data)
    vm.errors = {}
    form.$setPristine()

  submit = (form = null) ->
    vm.response.save().then((result) ->
      vm.errors = {}
      form.$setPristine() if form
      args = _.pick(result, ['consented', 'can_call', 'can_email'])
      $rootScope.$broadcast('subjectConsentResponse', args)
      $.extend(current_subject, args)

      if $stateParams.pass_through_sref
        $state.go($stateParams.pass_through_sref, _.extend($stateParams, {locale: vm.subject.preferred_language}))
      else
        if result.contactable
          $state.go($stateParams.pass_through_sref, $stateParams) if $stateParams.pass_through_sref
        else
          $state.go(failure_state, $stateParams) if failure_state
      return
    , (result) ->
      vm.errors = result.data.messages
      return
    )
    return

  vm = {
    agreement: null
    response: null
    current_locale: 'en_US'
    subject: current_subject
    campaign: recruiting_campaign
    interaction: subject_interaction

    removeExclusion: removeExclusion
    submit: submit
    cancel: cancel
    setLocale: setLocale

    languageHelper: languageHelper
    errors: {}
  }
  $scope.vm = vm

export default consentCtrl