import fileSaver from 'file-saver'
import cookies from "~angular/src/rj_shared/utils/cookies"

ctrl =  (
  $scope
  $uibModalInstance
  attachments
  parent
  can_manage
  FileAttachmentSvc
  appInfo
  FileUploader
  Restangular
) ->
  "ngInject"

  data = {
    closing: false
  }

  deleteAttachment = (file) ->
    file.remove().then ->
      vm.attachments.splice(vm.attachments.indexOf(file), 1)

  getAttachment = (file) ->
    xhr = new XMLHttpRequest()
    xhr.open('GET', "/api/v1/files/#{file.id}/attachment", true)
    xhr.responseType = 'blob'
    xhr.setRequestHeader('Authorization', "Bearer #{cookies.getCookie('auth')}")
    xhr.onload = (e) ->
      if (this.status == 200)
        blob = new Blob([this.response], {type: file.source_content_type})
        fileSaver.saveAs(blob, file.source_file_name)
      else
        # Unable to download file
    xhr.send()

  initUploader = ->
    vm.uploader = new FileUploader({
      url: "#{appInfo.api_url}/#{parent.route}/#{parent.id}/file_attachments",
      withCredentials: true
      headers:
        'Authorization': 'Bearer '+ cookies.getCookie('auth')
    })

    vm.uploader.onAfterAddingFile = (file) ->
      file.upload()

    vm.uploader.onCompleteItem = (fileItem, result, status, headers) ->
      vm.attachments.push(FileAttachmentSvc.convert(parent.route, parent.id, result.response))
      return

  cancel = ->
    $uibModalInstance.dismiss(vm.attachments)

  $scope.$on('modal.closing', (event, reason, closed) ->
    if data.closing == false
      event.preventDefault()
      data.closing = true
      $uibModalInstance.close(vm.attachments)
    return
  )

  vm =
    uploader: null
    parent: parent
    can_manage: can_manage
    attachments: attachments
    deleteAttachment: deleteAttachment
    getAttachment: getAttachment
    appInfo: appInfo
    cancel: cancel

  initUploader()

  $scope.vm = vm
  return

export default ctrl
