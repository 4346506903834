import urls from "~angular/src/rj_shared/utils/urls"
import cookies from "~angular/src/rj_shared/utils/cookies"
import tokenManager from "~angular/src/rj_shared/utils/token_manager"

window.PusherAuth = {
  calculate(api_version, token, subject_first) {
    if (token == null) {
      token = cookies.getCookie("auth")
    }
    if (subject_first == null) {
      subject_first = false
    }
    const part_token = tokenManager.get("survey_participant")
    const priority_token = subject_first ? part_token || token : token || part_token
    return {
      encrypted: true,
      authEndpoint: "/api/v1/pusher/auth",
      cluster: "mt1",
      enableStats: true,
      auth: {
        headers: {
          Authorization: "Bearer " + priority_token,
        },
      },
    }
  },
}

window.PusherKey = {
  calculate() {
    return process.env.PUSHER_KEY
  },
}
