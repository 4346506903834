const QuestionnaireTemplateSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("questionnaire_templates");
    },
    one(id) {
      return Restangular.one("questionnaire_templates", id);
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {
        title: "",
        question_template_folder_id: null,
        config: {},
        pages: [],
      };
      const temp = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, temp, "questionnaire_templates");
    },
  };

  return svc;
};

export default QuestionnaireTemplateSvc;
