import { each } from "lodash"

export let rjOffClick = [
  "$rootScope",
  "$parse",
  function rjOffClick($rootScope, $parse) {
    var id = 0
    var listeners = {}
    // add variable to detect touch users moving..
    var touchMove = false

    function offClickEventHandler(event) {
      // If event is a touchmove adjust touchMove state
      if (event.type === "touchmove") {
        touchMove = true
        // And end function
        return false
      }
      // This will always fire on the touchend after the touchmove runs...
      if (touchMove) {
        // Reset touchmove to false
        touchMove = false
        // And end function
        return false
      }
      var target = event.target || event.srcElement
      each(listeners, function (listener: any) {
        var isTargetFiltered = false

        var filters = listener.elm.getAttribute("rj-off-click-filter").split(",") //.map(x => x.trim());

        isTargetFiltered = false
        each(filters, function (selector) {
          if (target.closest(selector)) isTargetFiltered = true
        })
        if (!(listener.elm.contains(target) || isTargetFiltered)) {
          $rootScope.$evalAsync(function () {
            listener.cb(listener.scope, {
              $event: event,
            })
          })
        }
      })
    }

    // Add event listeners to handle various events. Destop will ignore touch events
    document.addEventListener("touchmove", offClickEventHandler, true)
    document.addEventListener("touchend", offClickEventHandler, true)
    document.addEventListener("mousedown", offClickEventHandler, true)

    return {
      restrict: "A",
      compile: function compile(elem, attrs) {
        var fn = $parse(attrs.rjOffClick)

        var elmId = id++
        var removeWatcher = void 0
        return function (scope, element) {
          var turnOn = function turnOn() {
            listeners[elmId] = {
              elm: element[0],
              cb: fn,
              scope: scope,
            }
          }

          var turnOff = function turnOff() {
            listeners[elmId] = null
            delete listeners[elmId]
          }

          if (attrs.rjOffClickIf) {
            removeWatcher = $rootScope.$watch(
              function () {
                return $parse(attrs.rjOffClickIf)(scope)
              },
              function (newVal) {
                if (newVal) turnOn()
                else turnOff()
              },
            )
          } else turnOn()

          scope.$on("$destroy", function () {
            turnOff()
            if (removeWatcher) {
              removeWatcher()
            }
            element = null
          })
        }
      },
    }
  },
]
