import * as angular from "angular"

sidebarContentTransclude = (
) ->
  "ngInject"

  {
    restrict: 'A'
    require: '^sidebarset'
    link: (scope, elm, attrs) ->
      sidebar = scope.$eval(attrs.sidebarContentTransclude)
      #Now our tab is ready to be transcluded: both the tab heading area
      #and the tab content area are loaded.  Transclude 'em both.
      sidebar.$transcludeFn sidebar.$parent, (contents) ->
        angular.forEach contents, (node) ->

            elm.append node
          return
        return
      return

  }

export default sidebarContentTransclude

