preferredTimeTz = (
) ->
  "ngInject"

  (time, timezone_id) ->
    return unless moment(time).isValid()

    if current_user.time_format
      if current_user.time_format == '24hour'
        time_format = "HH:mm"
      else
        time_format = "hh:mm A"
    else
      time_format = "hh:mm A"
    return moment.tz(time, timezone_id).format(time_format)
export default preferredTimeTz

