calendar = (
  $compile
) ->
  "ngInject"

  {
    restrict: 'A'
    scope:
      select: '&'
      actionLinks: '='
      ngModel: '='
      calendar: '='
    link: (scope, element, attrs) ->
      date = new Date
      d = date.getDate()
      m = date.getMonth()
      y = date.getFullYear()
      #Generate the Calendar
      base_cofig =
        header:
          right: ''
          center: 'prev, title, next'
          left: ''
        theme: true
        selectable: true
        selectHelper: true
        editable: false
        events: scope.ngModel || []
        #eventClick: (event) ->
        #  if (event.url)
        #    window.open(event.url)
        #    return false

        select: (start, end, allDay) ->
          scope.select
            start: start
            end: end
          return
      config = angular.extend base_cofig, scope.calendar
      $(element).fullCalendar config

      #Add action links in calendar header
      element.find('.fc-toolbar').append $compile(scope.actionLinks)(scope)
      return

  }

export default calendar
