const DemographicSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("demographics");
    },
    new(sensory_test, attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {
        name: null,
        id: null,
        demographics: [],
      };
      const me = jQuery.extend(true, new_attrs, attrs);
      if (sensory_test) {
        return Restangular.restangularizeElement(sensory_test, me, "demographic_categories");
      } else {
        return Restangular.restangularizeElement(null, me, "demographic_categories");
      }
    },
    // rangAs: (parent, obj) ->
    //   Restangular.restangularizeElement(parent, obj, "demographic_categories")
    lookup() {
      return Restangular.all("demographics/lookup");
    },
    fill() {
      return Restangular.all("demographic_categories/lookup");
    },
  };

  return svc;
};

export default DemographicSvc;
