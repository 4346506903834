const OnlineEventSvc = function (Restangular) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("online_events");
    },
    one(id) {
      return Restangular.one("online_events", id);
    },
    by_key(key) {
      return Restangular.one("online_events/show_by_key");
    },
  };

  return svc;
};

export default OnlineEventSvc;
