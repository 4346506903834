const AnalysisSettingsSvc = function (Restangular, $stateParams) {
  "ngInject";

  const svc = {
    all() {
      return Restangular.all("analysis_settings");
    },
    one(id) {
      return Restangular.one("analysis_settings", id);
    },
  };

  return svc;
};

export default AnalysisSettingsSvc;
