const AnalysisSvc = function (Restangular, $stateParams) {
  "ngInject";

  const svc = {
    export(id, params) {
      if (params == null) {
        params = {};
      }
      return Restangular.one("analyses", id).customPOST(params, "export");
    },
    calcs(id, endpoint, params) {
      if (params == null) {
        params = {};
      }
      params.endpoint = endpoint;
      return Restangular.one("analyses", id).customGET("calcs", params);
    },
    all() {
      return Restangular.all("analyses");
    },
    one(id) {
      return Restangular.one("analyses", id);
    },
    current() {
      return Restangular.one("analyses", $stateParams.analysis_id);
    },
    search(post_data) {
      return Restangular.all("analyses").customPOST(post_data, "search");
    },
    preview(analysis_type, sensory_test_id, copy_analysis_id = null) {
      return Restangular.all("analyses").customGET("preview", {
        analysis_type,
        sensory_test_id,
        copy_analysis_id,
      });
    },
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {
        name: "",
        description: "",
        analysis_type: "",
        status: "active",
      };
      const data = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, data, "analyses", false);
    },
  };

  return svc;
};

export default AnalysisSvc;
