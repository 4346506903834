import * as _ from "lodash";

const FormFieldSvc = function (Restangular, $stateParams) {
  "ngInject";

  const evaluateTrigger = function (field_id, target, form) {
    if (!(field_id > 0) || !target || !form) {
      return;
    }
    const field = _.find(form, field => field.id === field_id);
    const trigger_checks = [true];

    _.each(field.triggers, function (t) {
      const is_local = _.any(form, field => field.field_id === t.parent_field_id); //hacky bullshit
      if (is_local) {
        const answer = __guard__(getFieldAnswer(t.parent_field_id, target), x => x.answer_text);
        if (t.condition === 1) {
          return trigger_checks.push(t.answers.includes(answer));
        } else if (t.condition === 2 && answer) {
          return trigger_checks.push(!t.answers.includes(answer));
        }
      } else {
        if (t.is_visible == null) {
          //only check external triggers once
          t.is_visible = false;
          return field
            .customGET(`is_visible_for/${$stateParams.project_id}`, { skip_local: true })
            .then(result => (t.is_visible = result || false)); //if the server returns false restangular result is undefined
        } else {
          return trigger_checks.push(t.is_visible);
        }
      }
    });

    return _.all(trigger_checks);
  };

  var getFieldAnswer = function (field_id, target) {
    if (!target) {
      return;
    }
    let answer = _.find(target.field_answers, fa => fa.field_id === field_id);
    if (!answer) {
      answer = { field_id, answer_text: null };
    }
    return answer;
  };

  const svc = {
    all(owner_type) {
      return Restangular.all(`${owner_type}/form_fields`);
    },
    one(id) {
      return Restangular.one("form_fields", id);
    },
    evaluate_trigger: evaluateTrigger,
    new(attrs) {
      if (attrs == null) {
        attrs = {};
      }
      const new_attrs = {
        company_id: null,
        name: null,
        field_type: null,
      };
      const data = jQuery.extend(true, new_attrs, attrs);
      return Restangular.restangularizeElement(null, data, "form_fields", false);
    },
  };

  return svc;
};

export default FormFieldSvc;

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined;
}
