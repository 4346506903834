import * as _ from "lodash"

wizardNavCtrl = (
  $scope
  $rootScope
  $state
  $stateParams
  stepHelper
) ->
  "ngInject"

  ctrl = @

  ctrl.buildSteps = (progress) ->
    $scope.steps = stepHelper.buildSteps($scope.cfg, progress)
    return

  $rootScope.$on('step_complete', (event, progress, params) ->
    ctrl.buildSteps(progress)
    next_step = stepHelper.nextStep($scope.steps)
    if next_step && _.any(Object.keys(progress), (key) -> progress[key] == 'invalid') #next_step.status == 'invalid'
      goToStep(next_step, params)
    return
  )

  isDisabled = (step) ->
    stepHelper.isDisabled($scope.steps, step)

  isNext = (step) ->
    next = stepHelper.nextStep($scope.steps)
    next && step.id == next.id

  isCurrent = (step) ->
    current = stepHelper.currentStep($scope.steps)
    current && step.id == current.id

  isComplete = (step) ->
    step.status == 'valid'

  stepClicked = (step) ->
    unless isDisabled(step)
      goToStep(step)

  goToStep = (step, params = null) ->
    state = if Array.isArray(step.state) then _.first(step.state) else step.state
    $state.go(state, if params then params else $stateParams)

  vm = {
    isDisabled: isDisabled
    isNext: isNext
    isCurrent: isCurrent
    isComplete: isComplete
    stepClicked: stepClicked
  }
  $scope.vm = vm
  return

export default wizardNavCtrl

