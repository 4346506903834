import * as _ from "lodash"

globalAlertsCtrl = (
  $scope
  GlobalAlertSvc
  currentUser
) ->
  "ngInject"

  ack = (alert) ->
    GlobalAlertSvc.ack(alert)

  initialize = ->
    return unless _.includes currentUser.clearance_levels, 'view_global_alerts'
    GlobalAlertSvc.unacked()

  vm = {
    ack: ack
    alerts: []
  }

  initialize()

  $scope.vm = vm

export default globalAlertsCtrl
