miniSideCtrl = (
  $scope
  $previousState
) ->
  "ngInject"

  $scope.previousState = $previousState
  $scope.back = ->
    history.back()





export default miniSideCtrl

