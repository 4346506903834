const QRCode = require("easyqrcodejs");

const qrCode = function (ngToast, $uibModal) {
  "ngInject";

  return {
    restrict: "E",
    template: `\
<div style='display: flex; width: max-content; margin-top: 20px; flex-direction: column;'> \
<div> \
<a ng-if='showCopyButton' ng-click='copyToClipboard()' class='btn btn-xs btn-primary' style='width: 100%; display: flex; align-items: center; justify-content: center; font-weight: bold;'> \
<i class='fa fa-clipboard fa-flip-horizontal'></i> \
<span style='display: inline-block; margin-left: 5px;'>QR Code</span> \
</a> \
</div> \
<div class='qrcode' style='margin-top: 3px;'></div> \
</div>\
`,
    scope: {
      url: "@",
      showCopyButton: "=?",
      size: "=?",
    },

    link(scope, element, attrs) {
      const options = {
        text: scope.url,
        width: scope.size || 150,
        height: scope.size || 150,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      };

      new QRCode(element[0].querySelector(".qrcode"), options);

      scope.$watch("url", function (newVal, oldVal) {
        element[0].querySelector(".qrcode").getElementsByTagName("canvas")[0].remove();
        options.text = newVal;
        return new QRCode(element[0].querySelector(".qrcode"), options);
      });

      return (scope.copyToClipboard = function () {
        try {
          ClipboardItem;
          const canvas = element[0].querySelector(".qrcode").getElementsByTagName("canvas")[0];
          canvas.toBlob(blob => navigator.clipboard.write([new ClipboardItem({ "image/png": blob })]));
          return ngToast.success({ content: "QR Code copied to clipboard" });
        } catch (error) {
          if (navigator.userAgent.match(/Firefox/)) {
            return $uibModal.open({
              templateUrl: "rj_shared/firefox_warn_copy_modal.html",
              controller: function ($scope, $uibModalInstance) {
                return ($scope.close = () => $uibModalInstance.close());
              },
            });
          }
        }
      });
    },
  };
};

export default qrCode;
