const GlobalAlertSvc = function (Restangular, $rootScope, ngToast) {
  "ngInject";

  const endpoint = "global_alerts";

  const svc = {
    all() {
      return Restangular.all(endpoint).getList();
    },
    one(id) {
      return Restangular.one(endpoint, id);
    },
    new() {
      return {
        weight: 1,
        severity: 1,
        notification_start: moment().toISOString(),
        notification_end: null,
        global_alerts_roles: [],
      };
    },
    upsert(alert) {
      return Restangular.restangularizeElement(null, alert, endpoint).customPOST(alert, "upsert");
    },
    destroy(alert) {
      return Restangular.restangularizeElement(null, alert, endpoint).remove();
    },
    alertActive(alert) {
      const start = moment(alert.notification_start);
      const end = moment(alert.notification_end);
      if (alert.notification_start && moment() > start && (!alert.notification_end || moment() < end)) {
        return true;
      } else {
        return false;
      }
    },
    ack(alert) {
      return Restangular.restangularizeElement(null, alert, endpoint)
        .customPOST(alert, "ack")
        .then(
          result => ($rootScope.vm.alerts = result),
          error => ngToast.danger({ content: "Error acknowledging alert" }),
        );
    },
    unacked() {
      return Restangular.all(endpoint)
        .customGET("unacked")
        .then(
          result => ($rootScope.vm.alerts = result),
          error => ngToast.danger({ content: "Error retrieving alerts" }),
        );
    },
    resetAcked(alert) {
      return Restangular.restangularizeElement(null, alert, endpoint).customPOST(alert, "reset_acked");
    },
  };

  return svc;
};

export default GlobalAlertSvc;
