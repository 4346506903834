const Pad = {
  pad(xp) {
    if (!_.any(xp.samples)) {
      return null;
    }
    return padSample(xp.samples[0]);
  },

  padGeneric(n, width, z) {
    z = z || "0";
    n = n + "";
    if (n.length >= width) {
      return n;
    } else {
      return new Array(width - n.length + 1).join(z) + n;
    }
  },

  padSample(sample) {
    return padGeneric(sample.identifier, 8);
  },
};

window.pad = Pad.padGeneric;
export default Pad;
